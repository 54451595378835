const CardRow = ({ icon, label, value }) => {

  return (
      <div
        style={{ color: "#757575" }}
        className="flex w-full"
      >
        {icon !== undefined ? icon : null}
        <div className="w-full flex items-center">
          <span className="font-semibold text-[#4D5052] w-1/2">
            {label !== undefined ? `${label}: ` : ""}
          </span>
          <p className="w-1/2 mb-0 text-[#252627">{value ? value : 'N/A'}</p>
        </div>
      </div>
  );
};

export default CardRow;
