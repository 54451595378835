import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";

const CustomTextField = ({ name, control,value, label, }) => {
    return (
        // <Controller
        //           control={control}
        //           name={name}
        //           rules={{
        //             required: 'This field is required.',
        //           }}
        //           render={({ field }) => (
                    <TextField
                      value={value}
                      sx={{borderRadius:'8px'}}
                      className="m-8 w-full !rounded-xl"
                      variant="outlined"
                      disabled
                      // focused
                      // inputProps={{ readOnly: true }}
                      // {...field}
                      label={label}
                    />
                //   )}
                // />
    );
};

export default CustomTextField;