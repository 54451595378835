
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const tabs = [
  {
    label: "IDetails",
  },
  {
    label: "PInfo",
  },
  // {
  //   label: "Data Card",
  // },
  {
    label: "Catalog",
  },
];

function Tab({tab}) {
  const location = useLocation();
  const [currentTab,setCurrentTab] = useState()
  useEffect(()=>{
    const tempCurrentTab = new URLSearchParams(location.search).get('tab');
    setCurrentTab(tempCurrentTab);
    // console.log('location', tempCurrentTab === tab?.label);
  },[location])
  return (
    <Link to={{ pathname: location.pathname, search: `?tab=${tab?.label}` }}>
    <div className={` font-bold text-[24px] p-[8px] ${currentTab === tab?.label ? 'bg-info-content text-white' : ''}`}>
      {tab?.label}
    </div>
    </Link>
    
  )
}

export default function CustomTabs() {
  

  return (
    <div className='rounded-md border w-full flex justify-center space-x-2 bg-white'>
      {
        tabs.map((item, index) =>{
          return <Tab tab={item} key={index} />
        })
      }
    </div>
  );
}