import { Button } from "@mui/material";
import React from "react";

const FormContainer = ({ width, label, children,button }) => {
  return (
    <div>
      <div
        className={`flex justify-between border-b-[1px] py-[16px] px-[24px]  ${
          width ? width : "w-full"
        }`}
      >
        <h1  className="font-bold text-info-content text-xl">{label}</h1>
        {button}
      </div>
      {children}
    </div>
  );
};

export default FormContainer;
