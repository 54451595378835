import React from "react";
import Container from "../../../components/common/Container/Container";
import CardBody from "../../../components/common/Cards/CardBody/CardBody";
import CardRow from "../../../components/common/Cards/CardRow/CardRow";
import CardContainer from "../../../components/common/Cards/CardContainer/CardContainer";
import FormFieldContainer from "../../../components/common/FormFieldContainer/FormFieldContainer";

export default function Catalog({ specimenDetails }) {
  return (
    <CardContainer width="w-full mt-5">
      {specimenDetails ? (
        <CardBody label="Catalogue Identification">
          <div className="py-12 px-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
              <CardRow label="Registration No" value={specimenDetails?.regNo} />

              <CardRow
                label="Scientific Name"
                value={specimenDetails?.iDetails?.scientificName}
              />

              <CardRow
                label="English Name"
                value={specimenDetails?.iDetails?.englishName}
              />

              <CardRow  label="Catalog No" value={specimenDetails?.catalogue?.catalogNo} />

              <CardRow
                label="Copy No"
                value={specimenDetails?.catalogue?.copyNo}
              />
            </div>
            <div className="my-8 p-6 border rounded-md">
              <h2 className="my-3 font-semibold">Classification</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
                <CardRow
                  label="Phylum"
                  value={specimenDetails?.iDetails?.phylum}
                />
                <CardRow
                  label="Class"
                  value={specimenDetails?.iDetails?.class}
                />
                <CardRow
                  label="Order"
                  value={specimenDetails?.iDetails?.order}
                />
                <CardRow
                  label="Family"
                  value={specimenDetails?.iDetails?.family}
                />
              </div>
            </div>
            {/* <div className="flex my-8 flex-col md:flex-row w-full gap-x-0 md:gap-x-12 gap-y-12 md:gap-y-0">
              <div className="w-full p-6 border rounded-md">
                <h2 className="my-3 font-semibold">Synonyms</h2>
                <div className="flex flex-col space-y-2">
                  {synonyms.map((item, index) => (
                    <TextField
                      sx={{ borderRadius: "8px" }}
                      className="m-8 w-full !rounded-xl"
                      variant="outlined"
                      label={`Synonym - ${index + 1}`}
                      onChange={(e) => {
                        let tempSynonyms = [...synonyms];
                        tempSynonyms[index] = e.target.value;
                        setSynonyms(tempSynonyms);
                      }}
                    />
                  ))}
                </div>

                <div className="flex justify-end mt-6">
                  <CustomButton
                    label="Add"
                    icon={<IoAddCircleOutline />}
                    onClickHandler={() => {
                      setSynonyms([...synonyms, ""]);
                    }}
                  />
                </div>
              </div>
              <div className="w-full p-6 border rounded-md">
                <h2 className="my-3 font-semibold">Local Names</h2>
                <div className="flex flex-col space-y-2">
                  {localNames.map((item, index) => (
                    <TextField
                      sx={{ borderRadius: "8px" }}
                      className="m-8 w-full !rounded-xl"
                      variant="outlined"
                      label={`Local Name - ${index + 1}`}
                      onChange={(e) => {
                        let tempLocalNames = [...localNames];
                        tempLocalNames[index] = e.target.value;
                        setLocalNames(tempLocalNames);
                      }}
                    />
                  ))}
                </div>

                <div className="flex justify-end mt-6">
                  <CustomButton
                    label="Add"
                    icon={<IoAddCircleOutline />}
                    onClickHandler={() => {
                      setLocalNames([...localNames, ""]);
                    }}
                  />
                </div>
              </div>
            </div> */}
            <div className="flex my-8 flex-col md:flex-row w-full gap-x-0 md:gap-x-12 gap-y-12 md:gap-y-0">
              <div className="w-full p-6 border rounded-md">
                <h2 className="my-3 font-semibold text-[20px]">Specimen Location</h2>
                <h2 className="my-3 font-semibold text-[16px]">Display</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5 mb-8">
                  <CardRow
                    label="Copy No"
                    value={specimenDetails?.catalogue?.copyNo}
                  />
                  <CardRow
                    label="Dis Section"
                    value={specimenDetails?.catalogue?.disSection}
                  />
                  <CardRow
                    label="Dis Rk No"
                    value={specimenDetails?.catalogue?.disRkNo}
                  />
                  <CardRow
                    label="Dis Shelf Box No"
                    value={specimenDetails?.catalogue?.disShelfBoxNo}
                  />
                </div>
                <h2 className="my-3 font-semibold text-[16px]">Reference</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5 mt-4">
                  <CardRow
                    label="Ref Section"
                    value={specimenDetails?.catalogue?.disSection}
                  />
                  <CardRow
                    label="Ref Rk No"
                    value={specimenDetails?.catalogue?.disRkNo}
                  />
                  <CardRow
                    label="Ref Slf Box No"
                    value={specimenDetails?.catalogue?.disShelfBoxNo}
                  />
                </div>
              </div>
            </div>
            <FormFieldContainer label="Species Identification Information">
              <CardRow
                label="Species Data Sheet ID"
                value={specimenDetails?.catalogue?.spDataSheetNo}
              />

              <CardRow
                label="Identified By"
                value={specimenDetails?.catalogue?.identifiedBy}
              />

              <CardRow
                label="Confirmed By"
                value={specimenDetails?.catalogue?.confirmedBy}
              />

              <CardRow
                label="Validated By"
                value={specimenDetails?.catalogue?.validateBy}
              />

              <CardRow
                label="Accepted By"
                value={specimenDetails?.catalogue?.acceptedBy}
              />
            </FormFieldContainer>
            <div className="my-8 p-6 border rounded-md">
              <h2 className="my-3 font-semibold">Registration</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
                <CardRow
                  label="Registration Date"
                  value={specimenDetails?.iDetails?.collectionDate}
                />
                <CardRow
                  label="Field Collection No"
                  value={specimenDetails?.catalogue?.fieldColNo}
                />
                <CardRow
                  label="Collected from"
                  value={specimenDetails?.catalogue?.collectedFrom}
                />
                <CardRow
                  label="Specimen held in until preservation"
                  value={specimenDetails?.catalogue?.storingProcess}
                />
              </div>
            </div>
            
            <FormFieldContainer label="Preservation/Curation Information">
              <CardRow
                label="Preservation Start Date"
                value={specimenDetails?.catalogue?.preservationStartDate}
              />
              <CardRow
                label="Preservation End Date"
                value={specimenDetails?.catalogue?.preservationEndDate}
              />
              <CardRow
                label="Preservation Method"
                value={specimenDetails?.pInfo?.preservationMethod}
              />
              <CardRow
                label="Processed By"
                value={specimenDetails?.catalogue?.processedBy}
              />
              <CardRow
                label="Pretreatment Method"
                value={specimenDetails?.catalogue?.pretreatmentMethod}
              />
              <CardRow
                label="Brief Procedure"
                value={specimenDetails?.catalogue?.briefProcedure}
              />
              <CardRow
                label="Present Condition"
                value={specimenDetails?.catalogue?.presentCondition}
              />
            </FormFieldContainer>
            <FormFieldContainer label="Display Information">
              <CardRow
                label="Display Date"
                value={specimenDetails?.catalogue?.displayDate}
              />
              <CardRow
                label="Container Frame Box"
                value={specimenDetails?.catalogue?.containerFrameBox}
              />
              <CardRow
                label="Individual No"
                value={specimenDetails?.catalogue?.individualNo}
              />
              <CardRow
                label="Position"
                value={specimenDetails?.catalogue?.position}
              />
            </FormFieldContainer>
            <FormFieldContainer label="Place of Collection">
              <CardRow
                label="District"
                value={specimenDetails?.catalogue?.district}
              />
              <CardRow
                label="Collection Place"
                value={specimenDetails?.catalogue?.collectionPlace}
              />
              <CardRow
                label="Habitat"
                value={specimenDetails?.catalogue?.habitat}
              />
              <CardRow
                label="Microhabitat"
                value={specimenDetails?.catalogue?.microhabitat}
              />
              <CardRow
                label="GPS North"
                value={specimenDetails?.catalogue?.gpsNorth}
              />
              <CardRow
                label="GPS East"
                value={specimenDetails?.catalogue?.gpsEast}
              />
            </FormFieldContainer>
          </div>
        </CardBody>
      ) : null}
    </CardContainer>
  );
}
