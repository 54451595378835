import React from 'react';

const Logo = () => {
  return (
    <div>
      <img src="/assets/logo.png" height={120} alt="placeholder"/>
      {/* <h2 className='text-xl flex justify-center items-center w-full'>Padma Museum</h2> */}
    </div>
  );
};

export default Logo;