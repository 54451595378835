import React from "react";
import Button from "@mui/material/Button";

const CustomButton = ({ label, icon , onClickHandler,bgColor }) => {
  return (
    <Button className={`!text-white  ${bgColor ? bgColor : '!bg-info-content'}`} onClick={onClickHandler} variant="outlined" startIcon={icon}>
      {label}
    </Button>
  );
};

export default CustomButton;
