import React from "react";
import RingLoader from "react-spinners/RingLoader";

const Loader = ({ size=20, color='#002B3D',height='h-[150px]' }) => {
  return (
    <div className={`w-full flex justify-center items-center ${height} !my-[24px]`}>
      <RingLoader size={size} color={color} />
    </div>
  );
};

export default Loader;