import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdArticle, MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton/CustomButton";
import CustomModal from "../../../components/common/CustomModal/CustomModal";
import CustomIconButton from "../../../components/common/CustomIconButton/CustomIconButton";
import CreateUserModal from "../CreateUserForm/CreateUserForm";
import CreateUserForm from "../CreateUserForm/CreateUserForm";
import EditUserForm from "../EditUserForm/EditUserForm";
import { getMyDetails } from "../utils";
import { useDispatch } from "react-redux";
import { deleteUserAsync, getUsersAsync } from "../store/userSlice";

const columns = [
  { id: "name", label: "Name", minWidth: 50, align: "center" },
  { id: "email", label: "Email", minWidth: 120 },
  {
    id: "role",
    label: "Role",
    minWidth: 150,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "center",
  },
];

export default function UserTable({ data, totalCount, setSkip, setLimit }) {
  const dispatch = useDispatch()
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setSkip(page * rowsPerPage);
    setLimit(rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    if (data) {
      console.log("data: ", data);
      const tempRows = [];
      for (let i = 0; i < data.length; i++) {
        if (data) {
          const { _id,email, name, role } = data[i];
          tempRows.push({ _id,email, name, role });
        }
      }
      setRows(tempRows);
    }
  }, [data]);

  useEffect(() => {
    console.log("rows", rows);
  }, [rows]);
  const myDetails = getMyDetails()
  return (
    <Paper
      className="rounded-[15px] w-[270px] sm:w-[300px] md:w-4/5 border"
      sx={{ borderRadius: "15px" }}
    >
      <div className="flex justify-between items-center p-4 rounded-t-[15px] border-b border-slate-300">
        <h2 className="text-xl font-bold text-info-content">Users</h2>

        <CustomButton
          onClickHandler={() => setOpenNewUserModal(true)}
          label="Add User"
          icon={<IoAddCircleOutline />}
        />
      </div>
      {/* <CreateUserModal
        openNewUserModal={openNewUserModal}
        setOpenNewUserModal={setOpenNewUserModal}
        title="Add New User"
      /> */}

      <CustomModal
        modalOpen={openNewUserModal}
        setModalOpen={setOpenNewUserModal}
        title="Add New User"
      >
        <CreateUserForm setModalOpen={setOpenNewUserModal} />
      </CustomModal>
      <CustomModal
        modalOpen={openEditUserModal}
        setModalOpen={setOpenEditUserModal}
        title="Edit User"
      >
        <EditUserForm selectedData={selected} setModalOpen={setOpenEditUserModal} />
      </CustomModal>

      <TableContainer>
        {data?.length === 0 ? (
          <p className="text-center py-24">Sorry, No data available!</p>
        ) : (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      color: "#22a6b3",
                      fontSize: "16px",
                      minWidth: column.minWidth,
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.filter((row) => row._id !== myDetails._id)
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        // console.log('value', value);
                        if (column.id === "action") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <CustomIconButton
                                onClickHandler={() =>
                                  {setSelected(row)
                                  setOpenEditUserModal(true)}
                                }
                                icon={<FiEdit />}
                              />

                              <CustomIconButton
                                onClickHandler={async () => {
                                  console.log("Selected", row);
                                  await dispatch(deleteUserAsync(row?._id))
                                  dispatch(getUsersAsync())
                                }}
                                icon={<MdDelete />}
                              />
                            </TableCell>
                          );
                        } else
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                borderRight: "1px solid #E0E0E0",
                              }}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
}
