import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import * as React from "react";
import {
  AiOutlineBug,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { TbAlien } from "react-icons/tb";
import {
  MdAddBox,
  MdLabelImportantOutline,
  MdOutlineDashboard,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../../main/User/store/userSlice";
import Logo from "../../Logo/Logo";
import { groups } from "../../../config/config";
import SideBarItems from "./SideBarItems";


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => {
    return user.user;
  });

  React.useEffect(() => {
    console.log("user", user);
  }, [user]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  // setScreenWidth(window.innerWidth)
  const handleDrawerClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (isDesktopOrLaptop) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }, [isDesktopOrLaptop]);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <AppBar
        className="border-b !shadow-md"
        sx={{
          boxShadow: "none",
          backgroundColor: "#fff",
          marginLeft: open ? drawerWidth : "64.2px",
          width: open ? `calc(100% - ${drawerWidth}px)` : "calc(100% - 64.2px)",
        }}
        position="fixed"
        open={open}
      >
        <Toolbar sx={{ boxShadow: "none" }}>
          <div className="w-full flex justify-between">
            {open ? (
              <IconButton onClick={handleDrawerClose}>
                <AiOutlineMenuFold className="text-black" />
              </IconButton>
            ) : (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <AiOutlineMenuUnfold className="text-black" />
              </IconButton>
            )}
            <div className="dropdown dropdown-hover dropdown-end">
              <div className="flex items-center">
                <div tabIndex={0} className="avatar mr-[16px]">
                  <div className="w-9 h-9 rounded-full ring-[1px] ring-offset-base-100 ring-slate-900 ring-offset-2">
                    <img alt="avater" src="../../../assets/avater.jpg" />
                  </div>
                </div>
                <div className="flex flex-col ">
                  <p className="text-[16px] text-black font-bold">
                    {user?.name}
                  </p>
                  <p className="text-[12px] text-gray-400">{user?.role}</p>
                </div>
              </div>

              <ul
                tabIndex={0}
                className="dropdown-content menu p-2 shadow bg-white text-black rounded-xl w-52"
              >
                {/* <li>
                  <a>My Profile</a>
                </li> */}
                <li>
                  <button onClick={handleLogout}>Logout</button>
                </li>
              </ul>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader className="flex justify-center items-center w-full border-b !shadow-md">
          {open ? (
            <Logo />
          ) : (
            <img
              src="/assets/shortLogo.png"
              height={44}
              width={44}
              alt="placeholder"
            />
          )}
        </DrawerHeader>
        {/* <Divider /> */}
        <SideBarItems open={open} />
        {/* <Divider /> */}
      </Drawer>
      <div className="w-full">
        <DrawerHeader />
        <div className="w-full bg-[#f9f9f963] min-h-screen">{children}</div>
      </div>
    </Box>
  );
}
