import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdAccountCircle } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../../components/Logo/Logo";
import Loader from "../../../components/common/Loader/Loader";
import {
  createSpeciesProfileAsync,
  getSpeciesProfilesAsync,
} from "../store/speciesProfileSlice";
import CustomSelect from "../../../components/common/CustomSelect/CustomSelect";
import { Select } from "@mui/material";
import UncontrollrdCustomSelect from "../../../components/common/CustomSelect/UncontrollrdCustomSelect";
import { groups } from "../../../config/config";

const CreateSpeciesProfileForm = ({ setModalOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    reValidateMode: "onChange",
  });

  const onSubmit = async (data) => {
    console.log("data: ", data);
    const submittingData = {
      englishName: data.englishName,
      scientificName: data.scientificName,
      group: data.group,
      speciesProfileLink: data.speciesProfileLink,
    };
    console.log("SubmittingData: ", submittingData);
    try {
      const res = await dispatch(
        createSpeciesProfileAsync(submittingData)
      ).unwrap();
      dispatch(getSpeciesProfilesAsync());
      setModalOpen(false);
      navigate("/species-profile");
      console.log("res", res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="mx-auto w-[300px]">
      {loading ? (
        <Loader height="" size={100} />
      ) : (
        <form className="py-6 " onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-6">
            <Controller
              control={control}
              name="englishName"
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <TextField
                  sx={{ borderRadius: "8px" }}
                  className="m-8 w-full "
                  variant="outlined"
                  {...field}
                  label="English Name"
                />
              )}
            />
            <Controller
              control={control}
              name="scientificName"
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <TextField
                  sx={{ borderRadius: "8px" }}
                  className="m-8 w-full "
                  variant="outlined"
                  {...field}
                  label="Scientific Name"
                />
              )}
            />

            <UncontrollrdCustomSelect
              control={control}
              name="group"
              label="Group"
              menus={groups}
            />

            <Controller
              control={control}
              name="speciesProfileLink"
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <TextField
                  sx={{ borderRadius: "8px" }}
                  className="m-8 w-full "
                  variant="outlined"
                  {...field}
                  label="Species Profile Link"
                />
              )}
            />
          </div>

          <button class="bg-[#002D74] rounded-xl text-white py-2 mt-[36px] hover:scale-105 duration-300 w-full">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default CreateSpeciesProfileForm;
