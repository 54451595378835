import { Button, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import CardBody from "../../../../components/common/Cards/CardBody/CardBody";
import CardContainer from "../../../../components/common/Cards/CardContainer/CardContainer";
import CustomButton from "../../../../components/common/CustomButton/CustomButton";
import CustomSelect from "../../../../components/common/CustomSelect/CustomSelect";
import CustomTextField from "../../../../components/common/CustomTextField/CustomTextField";
import DisabledTextField from "../../../../components/common/DisabledTextField/DisabledTextField";
import FormFieldContainer from "../../../../components/common/FormFieldContainer/FormFieldContainer";
import FormContainer from "../../../../components/common/FormContainer/FormContainer";
import { useForm } from "react-hook-form";
import SubmitButton from "../../../../components/common/SubmitButton/SubmitButton";
import { updateSpecimenAsync } from "../../store/specimenSlice";
import { useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { convertDate, retrieveDate, showSuccessAlert } from "../../../../utils/utils";
import { MdClose } from "react-icons/md";
import CustomDatePicker from "../../../../components/common/CustomDatePicker/CustomDatePicker";

export default function UpdateCatalogue({ specimenDetails }) {
  const dispatch = useDispatch();
  const { specimenId } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
    reValidateMode: "onChange",
  });
  const [synonyms, setSynonyms] = useState([""]);
  const [localNames, setLocalNames] = useState([""]);
  const [position, setPosition] = useState(null);
  const [preservedAs, setPreservedAs] = useState(null);
  const [displayType, setDisplayType] = useState(null);
  const [collectedFrom, setCollectedFrom] = useState(null);
  const [displayDate, setDisplayDate] = useState(null);
  const [preservationStartDate, setPreservationStartDate] = useState(null);
  const [preservationEndDate, setPreservationEndDate] = useState(null);
  // useEffect(() => {
  //   console.log("historyOfPreservations", historyOfPreservations);
  // }, [historyOfPreservations]);

  useEffect(() => {
    if (specimenDetails) {
      console.log("specimenDetails", specimenDetails);
      setValue("catalogNo", specimenDetails?.catalogue?.catalogNo);
      setValue("copyNo", specimenDetails?.catalogue?.copyNo);
      setSynonyms(specimenDetails?.catalogue?.synonyms ? specimenDetails?.catalogue?.synonyms : [""]);
      setLocalNames(specimenDetails?.catalogue?.localNames ? specimenDetails?.catalogue?.localNames : [""]);
      setValue("disSection", specimenDetails?.catalogue?.disSection);
      setValue("disRkNo", specimenDetails?.catalogue?.disRkNo);
      setValue("disShelfBoxNo", specimenDetails?.catalogue?.disShelfBoxNo);
      setValue("refSection", specimenDetails?.catalogue?.disSection);
      setValue("refRkNo", specimenDetails?.catalogue?.disRkNo);
      setValue("refSlfBoxNo", specimenDetails?.catalogue?.disShelfBoxNo);

      setValue("spDataSheetNo", specimenDetails?.catalogue?.spDataSheetNo);

      setValue("identifiedBy", specimenDetails?.catalogue?.identifiedBy);

      setValue("confirmedBy", specimenDetails?.catalogue?.confirmedBy);

      setValue("validateBy", specimenDetails?.catalogue?.validateBy);

      setValue("acceptedBy", specimenDetails?.catalogue?.acceptedBy);

      //colFieldGift

      setValue("fieldColNo", specimenDetails?.catalogue?.fieldColNo);
      setCollectedFrom(specimenDetails?.catalogue?.collectedFrom);
      setValue("storingProcess", specimenDetails?.catalogue?.storingProcess);

      setPreservationStartDate(retrieveDate(specimenDetails?.catalogue?.preservationStartDate));
      setPreservationEndDate(retrieveDate(specimenDetails?.catalogue?.preservationEndDate));
      setValue(
        "killingMethod",
        specimenDetails?.catalogue?.killingMethod
      );
      setValue(
        "preservationMethod",
        specimenDetails?.catalogue?.preservationMethod
      );
      setValue("processedBy", specimenDetails?.catalogue?.processedBy);
      setValue(
        "pretreatmentMethod",
        specimenDetails?.catalogue?.pretreatmentMethod
      );
      setValue("briefProcedure", specimenDetails?.catalogue?.briefProcedure);
      setValue(
        "presentCondition",
        specimenDetails?.catalogue?.presentCondition
      );

      setValue("displayDate", specimenDetails?.pInfo?.displayDate);
      setDisplayDate(
        retrieveDate(specimenDetails?.catalogue?.displayDate)
      );
      setDisplayType(specimenDetails?.catalogue?.containerFrameBox);
      setValue("individualNo", specimenDetails?.catalogue?.individualNo);
      setPosition(specimenDetails?.catalogue?.position);

      setValue("district", specimenDetails?.catalogue?.district);
      setValue("collectionPlace", specimenDetails?.catalogue?.collectionPlace);
      setValue("habitat", specimenDetails?.catalogue?.habitat);
      setValue("microhabitat", specimenDetails?.catalogue?.microhabitat);
      setValue("gpsNorth", specimenDetails?.catalogue?.gpsNorth);
      setValue("gpsEast", specimenDetails?.catalogue?.gpsEast);
    }
  }, [specimenDetails]);
  const onSubmit = async (data) => {
    console.log("submitted", data);
    console.log("data: ", specimenId, data);
    const submittingData = {
      regNo: specimenDetails?.regNo,
      iDetails: { ...specimenDetails?.iDetails },
      pInfo: { ...specimenDetails?.pInfo },
      dataCard: {},
      catalogue: {
        catalogNo: data?.catalogNo,
        copyNo: data?.copyNo,
        synonyms: synonyms,
        localNames: localNames,
        disSection: data?.disSection,
        disRkNo: data?.disRkNo,
        disShelfBoxNo: data?.disShelfBoxNo,
        refSection: data?.refSection,
        refRkNo: data?.refRkNo,
        refSlfBoxNo: data?.refSlfBoxNo,
        fieldColNo: data?.fieldColNo,
        collectedFrom: collectedFrom,
        storingProcess: data?.storingProcess,
        spDataSheetNo: data?.spDataSheetNo,
        identifiedBy: data?.identifiedBy,
        confirmedBy: data?.confirmedBy,
        validateBy: data?.validateBy,
        acceptedBy: data?.acceptedBy,
        preservationStartDate: convertDate(preservationStartDate),
        preservationEndDate: convertDate(preservationEndDate),
        killingMethod: data?.killingMethod,
        preservationMethod: data?.preservationMethod,
        processedBy: data?.processedBy,
        pretreatmentMethod: data?.pretreatmentMethod,
        briefProcedure: data?.briefProcedure,
        presentCondition: data?.presentCondition,
        displayDate: convertDate(displayDate),
        containerFrameBox: displayType,
        individualNo: data?.individualNo,
        position: position,
        district: data?.district,
        collectionPlace: data?.collectionPlace,
        habitat: data?.habitat,
        microhabitat: data?.microhabitat,
        gpsNorth: data?.gpsNorth,
        gpsEast: data?.gpsEast,
      },
    };
    console.log("SubmittingData: ", submittingData);

    dispatch(updateSpecimenAsync({ specimenId, submittingData }))
      .unwrap()
      .then((res) => {
        console.log(res);
        showSuccessAlert({
          title: "Success!",
          description: "Catalogue Updated Successfully!",
        });
        Navigate(`/specimen-details/${specimenId}?tab=Catalog`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContainer width="w-full mt-5">
        {specimenDetails ? (
          <FormContainer
            label="Catalogue Identification"
            button={<SubmitButton />}
          >
            <div className="py-12 px-12">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
                <DisabledTextField
                  label="Registration No"
                  value={specimenDetails?.regNo}
                />

                <DisabledTextField
                  label="Scientific Name"
                  value={specimenDetails?.iDetails?.scientificName}
                />

                <DisabledTextField
                  label="English Name"
                  value={specimenDetails?.iDetails?.englishName}
                />

                <CustomTextField
                  name="catalogNo"
                  label="Catalog No"
                  control={control}
                />

                <CustomTextField
                  name="copyNo"
                  label="Copy No"
                  control={control}
                />
              </div>
              <div className="my-8 p-6 border rounded-md">
                <h2 className="my-3 font-semibold">Classification</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
                  <DisabledTextField
                    label="Phylum"
                    value={specimenDetails?.iDetails?.phylum}
                  />
                  <DisabledTextField
                    label="Class"
                    value={specimenDetails?.iDetails?.class}
                  />
                  <DisabledTextField
                    label="Order"
                    value={specimenDetails?.iDetails?.order}
                  />
                  <DisabledTextField
                    label="Family"
                    value={specimenDetails?.iDetails?.family}
                  />
                </div>
              </div>
              <div className="flex my-8 flex-col md:flex-row w-full gap-x-0 md:gap-x-12 gap-y-12 md:gap-y-0">
                <div className="w-full p-6 border rounded-md">
                  <h2 className="my-3 font-semibold">Synonyms</h2>
                  <div className="flex flex-col space-y-4">
                    {synonyms?.map((item, index) => (
                      <div className="flex items-center">
                        <TextField
                          sx={{ borderRadius: "8px" }}
                          className="m-8 w-full !rounded-xl"
                          variant="outlined"
                          label={`Synonym - ${index + 1}`}
                          value={item}
                          onChange={(e) => {
                            setSynonyms((prevData) =>
                              prevData.map((item, i) => {
                                if (index === i) {
                                  return e.target.value;
                                }
                                return item;
                              })
                            );
                          }}
                        />
                        <div
                          className="flex justify-center items-center px-2"
                          onClick={(e) => {
                            setSynonyms((prevData) =>
                              prevData.filter((item, i) => i !== index)
                            );
                          }}
                        >
                          <IconButton aria-label="delete">
                            <MdClose className="text-[#EB5757]" />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex mt-6">
                    <CustomButton
                      label="Add"
                      icon={<IoAddCircleOutline />}
                      onClickHandler={() => {
                        setSynonyms([...synonyms, ""]);
                      }}
                    />
                  </div>
                </div>
                <div className="w-full p-6 border rounded-md">
                  <h2 className="my-3 font-semibold">Local Names</h2>
                  <div className="flex flex-col space-y-4">
                    {localNames?.map((item, index) => (<div className="flex items-center">
                      <TextField
                        sx={{ borderRadius: "8px" }}
                        className="m-8 w-full !rounded-xl"
                        variant="outlined"
                        label={`Local Name - ${index + 1}`}
                        value={item}
                        onChange={(e) => {
                          setLocalNames((prevData) =>
                            prevData.map((item, i) => {
                              if (index === i) {
                                return e.target.value;
                              }
                              return item;
                            })
                          );
                        }}
                      />
                      <div
                          className="flex justify-center items-center px-2"
                          onClick={(e) => {
                            setSynonyms((prevData) =>
                              prevData.filter((item, i) => i !== index)
                            );
                          }}
                        >
                          <IconButton aria-label="delete">
                            <MdClose className="text-[#EB5757]" />
                          </IconButton>
                        </div>
                        </div>
                    ))}
                  </div>

                  <div className="flex mt-6">
                    <CustomButton
                      label="Add"
                      icon={<IoAddCircleOutline />}
                      onClickHandler={() => {
                        setLocalNames([...localNames, ""]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
              <CustomTextField
                  name="specimenType"
                  label="Specimen Type"
                  control={control}
                />
              <CustomSelect
                  label="Preserved As"
                  defaultValue={preservedAs}
                  setSelected={setPreservedAs}
                  menus={[
                    { label: "Reference", value: "Reference" },
                    { label: "Display Material", value: "Display material" }
                  ]}
                />
              </div>
              
              <div className="flex my-8 flex-col md:flex-row w-full gap-x-0 md:gap-x-12 gap-y-12 md:gap-y-0">
                <div className="w-full p-6 border rounded-md">
                  <h2 className="my-3 font-semibold text-[20px]">Specimen Location</h2>
                  <h2 className="my-3 font-semibold text-[16px]">Display</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5 mb-8">
                    <CustomTextField
                      name="disSection"
                      label="Display Section Name"
                      control={control}
                    />

                    <CustomTextField
                      name="disRkNo"
                      label="Rack No"
                      control={control}
                    />
                    <CustomTextField
                      name="disShelfBoxNo"
                      label="Shelf/box No"
                      control={control}
                    />
                  </div>
                  <h2 className="my-3 font-semibold text-[16px]">Reference</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5 mt-4">
                    <CustomTextField
                      name="refSection"
                      label="Reference Section Name"
                      control={control}
                    />
                    <CustomTextField
                      name="refRkNo"
                      label="Rack No"
                      control={control}
                    />

                    <CustomTextField
                      name="refSlfBoxNo"
                      label="Shelf/Box No"
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <FormFieldContainer label="Species Identification Information">
                <CustomTextField
                  name="spDataSheetNo"
                  label="Species Data Sheet ID"
                  control={control}
                />
                <CustomTextField
                  name="identifiedBy"
                  label="Identified By"
                  control={control}
                />
                <CustomTextField
                  label="Confirmed By"
                  name="confirmedBy"
                  control={control}
                />
                <CustomTextField
                  name="validateBy"
                  label="Validated By"
                  control={control}
                />
                <CustomTextField
                  label="Accepted By"
                  name="acceptedBy"
                  control={control}
                />
              </FormFieldContainer>
              <div className="my-8 p-6 border rounded-md">
                <h2 className="my-3 font-semibold">Registration</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
                  <DisabledTextField
                    label="Registration Date"
                    value={specimenDetails?.iDetails?.collectionDate}
                  />
                  <CustomTextField
                    name="fieldColNo"
                    label="Field Collection No"
                    control={control}
                  />
                  <CustomSelect
                    label="Collected from"
                    defaultValue={collectedFrom}
                    setSelected={setCollectedFrom}
                    menus={[
                      { label: "Field", value: "Field" },
                      { label: "Gift", value: "Gift" },
                    ]}
                  />

                  <CustomTextField
                    name="storingProcess"
                    label="Specimen held in until preservation"
                    control={control}
                  />
                </div>
              </div>
              
              <FormFieldContainer label="Preservation/Curation Information">
              <CustomDatePicker
                  label="Preservation Start Date"
                  defaultValue={preservationStartDate}
                  setDate={setPreservationStartDate}
                />
              <CustomDatePicker
                  label="Date of Completion"
                  defaultValue={preservationEndDate}
                  setDate={setPreservationEndDate}
                />
                <CustomTextField
                  label="Killing Method"
                  name="killingMethod"
                  control={control}
                />
                <CustomTextField
                  name="preservationMethod"
                  label="Preservation Method"
                  control={control}
                />
                <CustomTextField
                  label="Processed by"
                  name="processedBy"
                  control={control}
                />
                <CustomTextField
                  label="Pre-treatment Method"
                  name="pretreatmentMethod"
                  control={control}
                />
                <CustomTextField
                  label="Brief Preservation Procedure"
                  name="briefProcedure"
                  control={control}
                />
                <CustomTextField
                  label="Present Condition/Status"
                  name="presentCondition"
                  control={control}
                />
              </FormFieldContainer>
              <FormFieldContainer label="Display Information">
                <CustomDatePicker
                  label="Display Date"
                  defaultValue={displayDate}
                  setDate={setDisplayDate}
                />
                <CustomSelect
                  label="Container/Frame/Box"
                  defaultValue={displayType}
                  setSelected={setDisplayType}
                  menus={[
                    { label: "Container", value: "Container" },
                    { label: "Frame", value: "Frame" },
                    { label: "Box", value: "Box" },
                  ]}
                />
                <CustomTextField
                  label="No. of Individuals"
                  name="individualNo"
                  control={control}
                />
                <CustomSelect
                  label="Position"
                  defaultValue={position}
                  setSelected={setPosition}
                  menus={[
                    { label: "Upright", value: "Upright" },
                    { label: "Horizontal", value: "Horizontal" },
                  ]}
                />
              </FormFieldContainer>
              <FormFieldContainer label="Place of Collection">
                <CustomTextField
                  name="district"
                  label="District"
                  control={control}
                />
                <CustomTextField
                  label="Collection Place"
                  name="collectionPlace"
                  control={control}
                />
                <CustomTextField
                  label="Habitat"
                  name="habitat"
                  control={control}
                />
                <CustomTextField
                  label="Microhabitat"
                  name="microhabitat"
                  control={control}
                />
                <CustomTextField
                  label="GPS North"
                  name="gpsNorth"
                  control={control}
                />
                <CustomTextField
                  label="GPS East"
                  name="gpsEast"
                  control={control}
                />
              </FormFieldContainer>
            </div>
          </FormContainer>
        ) : null}
      </CardContainer>
    </form>
  );
}
