import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

const UncontrollrdCustomSelect = ({
  name,
  label,
  menus,
  showError,
  withoutLabel,
  control,
  defaultValue,
  ...props
}) => {
  const labelId = `${name}-label`;

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={showError}>
        {label && (
          <InputLabel className="text-[14px]" id={labelId}>
            {label}
          </InputLabel>
        )}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <Select
              sx={
                withoutLabel && {
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 },
                }
              }
              color="primary"
              className="bg-[#F9F9F9] text-sm"
              labelId={labelId}
              id={`${name}-select`}
              value={field.value}
              label={label}
              onChange={(e) => field.onChange(e.target.value)}
              {...props}
            >
              {menus?.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {showError && (
          <FormHelperText>This field is required</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default UncontrollrdCustomSelect;
