import React, { useEffect } from "react";
import CardContainer from "../../../../components/common/Cards/CardContainer/CardContainer";
import CardBody from "../../../../components/common/Cards/CardBody/CardBody";
import CustomTextField from "../../../../components/common/CustomTextField/CustomTextField";
import DisabledTextField from "../../../../components/common/DisabledTextField/DisabledTextField";
import { Button, IconButton, TextField } from "@mui/material";
import CustomButton from "../../../../components/common/CustomButton/CustomButton";
import { IoAddCircleOutline } from "react-icons/io5";
import { useState } from "react";
import { set, useForm } from "react-hook-form";
import CustomDatePicker from "../../../../components/common/CustomDatePicker/CustomDatePicker";
import FormContainer from "../../../../components/common/FormContainer/FormContainer";
import SubmitButton from "../../../../components/common/SubmitButton/SubmitButton";
import { updateSpecimenAsync } from "../../store/specimenSlice";
import { Navigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  convertDate,
  retrieveDate,
  showSuccessAlert,
  toastify,
} from "../../../../utils/utils";
import { MdClose } from "react-icons/md";

export default function UpdatePInfo({
  specimenDetails
}) {
  const dispatch = useDispatch();
  const { specimenId } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
    reValidateMode: "onChange",
  });
  const [speciesConfirmDate, setSpeciesConfirmDate] = useState(new Date());
  const [historyOfPreservations, setHistoryOfPreservations] = useState([{}]);
  useEffect(() => {
    console.log(
      "speciesConfirmDate",
      speciesConfirmDate,
      convertDate(speciesConfirmDate),
      retrieveDate(speciesConfirmDate)
    );
  }, [speciesConfirmDate]);
  useEffect(() => {
    console.log("historyOfPreservations", historyOfPreservations);
  }, [historyOfPreservations]);
  useEffect(() => {
    setValue("museumRegNo", specimenDetails?.pInfo?.museumRegNo);
    setValue("fieldCollectionNo", specimenDetails?.pInfo?.fieldCollectionNo);
    if(specimenDetails?.pInfo?.historyOfPreservations){
      setHistoryOfPreservations(specimenDetails?.pInfo?.historyOfPreservations)
    }
    setValue("misSheetNo", specimenDetails?.pInfo?.misSheetNo);
    setValue("nameOfSpecimen", specimenDetails?.pInfo?.nameOfSpecimen);
    setValue("museumEntryDate", specimenDetails?.pInfo?.museumEntryDate);
    setValue(
      "speciesIdentifiedBy",
      specimenDetails?.pInfo?.speciesIdentifiedBy
    );
    setValue(
      "speciesIdConfirmedBy",
      specimenDetails?.pInfo?.speciesIdConfirmedBy
    );
    setSpeciesConfirmDate(
      retrieveDate(specimenDetails?.pInfo?.speciesConfirmationDate)
    );
  }, [specimenDetails]);
  const onSubmit = async (data) => {
    console.log("submitted", data);
    // console.log("data: ", specimenId, data);
    const submittingData = {
      regNo: specimenDetails?.regNo,
      iDetails: {
        ...specimenDetails?.iDetails,
      },
      pInfo: {
        museumRegNo: data?.museumRegNo,
        fieldCollectionNo: data?.fieldCollectionNo,
        misSheetNo: data?.misSheetNo,
        historyOfPreservations: historyOfPreservations.length === 1 && Object.getOwnPropertyNames(historyOfPreservations[0]).length === 0 ? null : historyOfPreservations,
        nameOfSpecimen: data?.nameOfSpecimen,
        museumEntryDate: data?.museumEntryDate,
        speciesIdentifiedBy: data?.speciesIdentifiedBy,
        speciesIdConfirmedBy: data?.speciesIdConfirmedBy,
        speciesConfirmationDate: convertDate(speciesConfirmDate),
      },
      dataCard: {},
      catalogue: {
        ...specimenDetails?.catalogue,
      },
    };
    console.log("SubmittingData: ", submittingData);

    dispatch(updateSpecimenAsync({ specimenId, submittingData }))
      .unwrap()
      .then((res) => {
        showSuccessAlert({
          title: "Success!",
          description: "PInfo Updated Successfully!",
        });
        console.log(res);
        Navigate(`/specimen-details/${specimenId}?tab=PInfo`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContainer width="w-full mt-5">
        {specimenDetails ? (
          <FormContainer
            label="Processing and Preservation Information Datasheet"
            button={<SubmitButton />}
          >
            <div className="py-12 px-12">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
                <DisabledTextField
                  label="Registration No"
                  value={specimenDetails?.regNo}
                />

                <DisabledTextField
                  label="Scientific Name"
                  value={specimenDetails?.iDetails?.scientificName}
                />

                <DisabledTextField
                  label="English Name"
                  value={specimenDetails?.iDetails?.englishName}
                />
                <CustomTextField
                  name="fieldCollectionNo"
                  label="Field Collection No"
                  control={control}
                />
                <CustomTextField
                  name="museumRegNo"
                  label="Museum Registration No"
                  control={control}
                />
                <DisabledTextField
                  label="Catalog No"
                  value={specimenDetails?.catalogue?.catalogNo}
                />

                <CustomTextField
                  name="misSheetNo"
                  label="MIS Sheet No (if any)"
                  control={control}
                />

                <DisabledTextField
                  label="Name of Specimen"
                  value={specimenDetails?.iDetails?.localName}
                />

                <DisabledTextField
                  label="Museum Entry Date"
                  value={specimenDetails?.iDetails?.collectionDate}
                />
              </div>
              {historyOfPreservations ? (
                <div className="my-8 p-6 border rounded-md">
                  <h2 className="my-3 font-semibold">
                    History of Preservation
                  </h2>
                  {historyOfPreservations.map((item, index) => (
                    <div>
                      <h2 className="my-3 font-semibold">
                        History of Preservation - {index + 1}
                      </h2>
                      <div className="flex w-full items-center mb-4">
                      <div
                        key={index}
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-5"
                      >
                        <CustomDatePicker
                          label="Processing Date"
                          defaultValue={retrieveDate(item?.processingDate)}
                          setDate={(value) => {
                            setHistoryOfPreservations((prevData) =>
                              prevData.map((item, i) => {
                                if (index === i) {
                                  return {
                                    ...item,
                                    ["processingDate"]: convertDate(value),
                                  };
                                }
                                return item;
                              })
                            );
                          }}
                        />
                        <TextField
                          sx={{ borderRadius: "8px" }}
                          className="m-8 w-full !rounded-xl"
                          variant="outlined"
                          label="Processed By"
                          value={item?.processedBy}
                          onChange={(e) => {
                            setHistoryOfPreservations((prevData) =>
                              prevData.map((item, i) => {
                                if (index === i) {
                                  return {
                                    ...item,
                                    ["processedBy"]: e.target.value,
                                  };
                                }
                                return item;
                              })
                            );
                          }}
                        />
                        <TextField
                          sx={{ borderRadius: "8px" }}
                          className="m-8 w-full !rounded-xl"
                          variant="outlined"
                          label="Description of Processing"
                          value={item?.descriptionOfProcessing}
                          onChange={(e) => {
                            setHistoryOfPreservations((prevData) =>
                              prevData.map((item, i) => {
                                if (index === i) {
                                  return {
                                    ...item,
                                    ["descriptionOfProcessing"]: e.target.value,
                                  };
                                }
                                return item;
                              })
                            );
                          }}
                        />
                        
                      </div>
                          <div
                            className="flex justify-center items-center px-2"
                            onClick={(e) => {
                              setHistoryOfPreservations((prevData) =>
                                prevData.filter((item, i) => i !== index)
                              );
                            }}
                          >
                            <IconButton aria-label="delete">
                              <MdClose className="text-[#EB5757]" />
                            </IconButton>
                          </div>
                    </div>
                    </div>
                  ))}
                  <div className="flex ">
                    <CustomButton
                      label="Add"
                      icon={<IoAddCircleOutline />}
                      onClickHandler={() => {
                        setHistoryOfPreservations([
                          ...historyOfPreservations,
                          {},
                        ]);
                      }}
                    />
                  </div>
                </div>
              ) : null}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
                <CustomTextField
                  name="speciesIdentifiedBy"
                  label="Species Identified By"
                  control={control}
                />

                <CustomTextField
                  name="speciesIdConfirmedBy"
                  label="Species ID Confirmed By"
                  control={control}
                />

                <CustomDatePicker
                  label="Date of Specimen Confirmation"
                  defaultValue={speciesConfirmDate}
                  setDate={setSpeciesConfirmDate}
                />
              </div>
            </div>
          </FormContainer>
        ) : null}
      </CardContainer>
    </form>
  );
}
