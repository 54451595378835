import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../../axios";

const moduleName = "user";
const apiRoute = `/users`;

export const getUsersAsync = createAsyncThunk(
  `${moduleName}/getUsersAsync`,
  async (payload) => {
    const response = await instance.get(`${apiRoute}?skip=${payload?.skip}&limit=${payload?.limit}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = response.data;
    return data;
  }
);
export const getUserDataAsync = createAsyncThunk(
  `${moduleName}/getUserDataAsync`,
  async ({userId}) => {
    const response = await instance.get(`${apiRoute}/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = response.data;
    console.log("data", data);
    return data;
  }
);

export const loginUserAsync = createAsyncThunk(
  `${moduleName}/loginUserAsync`,
  async ({email,password}) => {
    console.log("loginUserAsync", {email,password});

    const response = await instance.post(`${apiRoute}/login`, {email,password}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    
    const data = response.data;
    console.log("data", data);
    return data;
  }
);

export const createUserAsync = createAsyncThunk(
  `${moduleName}/createUserAsync`,
  async (payload) => {
    console.log("createUserAsync", payload);

    const response = await instance.post(`${apiRoute}/register`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    
    const data = response.data;
    console.log("data", data);
    return data;
  }
);
export const updateUserAsync = createAsyncThunk(
  `${moduleName}/updateUserAsync`,
  async ({userId,submittingData}) => {
    console.log("updateUserAsync", {userId,submittingData});

    const response = await instance.put(`${apiRoute}/${userId}`, submittingData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    
    const data = response.data;
    console.log("data", data);
    return data;
  }
);

export const deleteUserAsync = createAsyncThunk(
  `${moduleName}/deleteUserAsync`,
  async (userId) => {
    console.log("deleteUserAsync", {userId});

    const response = await instance.delete(`${apiRoute}/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = response.data;
    console.log("data", data);
    return data;
  }
);


const userSlice = createSlice({
  name: moduleName,
  initialState: {
    publicUser: null,
    data: null,
    user: null,
    userDetails: null,
    users: null,
    specimenDetails: null,
    newUser: null,
    isAuthenticated: false,
    loading: false,
  },
  reducers: {
    checkIfAuthenticated: (state) => {
      if(localStorage.getItem("user") || state.user) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    },
    logout: (state) => {
      // This action will reset the user data and isAuthenticated status
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem("user");
    },
  },
  extraReducers: {
    [loginUserAsync.pending]: (state) => {
      state.loading = true;
    },
    [loginUserAsync.fulfilled]: (state, action) => {
      // console.log('action', action)
      state.loading = false;
      state.user = action.payload;
    },
    [loginUserAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getUsersAsync.pending]: (state) => {
      state.loading = true;
    },
    [getUsersAsync.fulfilled]: (state, action) => {
      // console.log('action', action)
      state.loading = false;
      state.users = action.payload;
    },
    [getUsersAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getUserDataAsync.pending]: (state) => {
      state.loading = true;
    },
    [getUserDataAsync.fulfilled]: (state, action) => {
      // console.log('action', action)
      state.loading = false;
      state.userDetails = action.payload;
    },
    [getUserDataAsync.rejected]: (state) => {
      state.loading = false;
    },
    [createUserAsync.pending]: (state) => {
      state.loading = true;
    },
    [createUserAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.newUser = action.payload;  
    },
    [createUserAsync.rejected]: (state) => {
      state.loading = false;
    },
    [updateUserAsync.pending]: (state) => {
      state.loading = true;
    },
    [updateUserAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateUserAsync.rejected]: (state) => {
      state.loading = false;
    },
    [deleteUserAsync.pending]: (state) => {
      state.loading = true;
    },
    [deleteUserAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteUserAsync.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { checkIfAuthenticated, logout } = userSlice.actions;
export default userSlice.reducer;
