import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../../axios";

const moduleName = "specimen";
const apiRoute = `/specimens`;

export const getSpecimenAsync = createAsyncThunk(
  `${moduleName}/getSpecimenAsync`,
  async ({keyword,skip,limit,group}) => {
    console.log('hello')
    const response = await instance.get(`${apiRoute}?keyword=${keyword}&skip=${skip}&limit=${limit}&group=${group}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = response.data;
    return data;
  }
);
export const getSpecimenGroupsAsync = createAsyncThunk(
  `${moduleName}/getSpecimenGroupsAsync`,
  async () => {
    const response = await instance.get(`/dashboard`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = response.data;
    return data;
  }
);
// export const getSpecimenAsync = createAsyncThunk(
//   `${moduleName}/getSpecimenAsync`,
//   async ({regNo, group, englishName,skip,limit}) => {
//     console.log('hello')
//     const response = await instance.get(`${apiRoute}?regNo=${regNo}&group=${group}&englishName=${englishName}&skip=${skip}&limit=${limit}`, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
//       },
//     });

//     const data = response.data;
//     return data;
//   }
// );
export const getSingleSpecimenDetailsAsync = createAsyncThunk(
  `${moduleName}/getSingleSpecimenDetailsAsync`,
  async ({specimenId}) => {
    const response = await instance.get(`${apiRoute}/${specimenId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = response.data;
    console.log("data", data);
    return data;
  }
);

export const createSpecimenAsync = createAsyncThunk(
  `${moduleName}/createSpecimenAsync`,
  async (payload) => {
    console.log("createSpecimenAsync", payload);

    const response = await instance.post(`${apiRoute}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    
    const data = response.data;
    console.log("data", data);
    return data;
  }
);
export const updateSpecimenAsync = createAsyncThunk(
  `${moduleName}/updateSpecimenAsync`,
  async ({specimenId,submittingData}) => {
    console.log("updateSpecimenAsync", {specimenId,submittingData});

    const response = await instance.put(`${apiRoute}/${specimenId}`, submittingData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    
    const data = response.data;
    console.log("data", data);
    return data;
  }
);

const specimenSlice = createSlice({
  name: "specimen",
  initialState: {
    publicUser: null,
    data: null,
    specimens: null,
    specimenGroups: null,
    specimenDetails: null,
    newSpecimen: null,
    loading: false,
  },
  reducers: {
    testReducer: (state, action) => {
      console.log("testing reducer", state.data, action.payload);
    },
  },
  extraReducers: {
    [getSpecimenAsync.pending]: (state) => {
      state.loading = true;
    },
    [getSpecimenAsync.fulfilled]: (state, action) => {
      // console.log('action', action)
      state.loading = false;
      state.specimens = action.payload;
    },
    [getSpecimenAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getSpecimenGroupsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getSpecimenGroupsAsync.fulfilled]: (state, action) => {
      // console.log('action', action)
      state.loading = false;
      state.specimenGroups = action.payload;
    },
    [getSpecimenGroupsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [getSingleSpecimenDetailsAsync.pending]: (state) => {
      state.loading = true;
    },
    [getSingleSpecimenDetailsAsync.fulfilled]: (state, action) => {
      // console.log('action', action)
      state.loading = false;
      state.specimenDetails = action.payload;
    },
    [getSingleSpecimenDetailsAsync.rejected]: (state) => {
      state.loading = false;
    },
    [createSpecimenAsync.pending]: (state) => {
      state.loading = true;
    },
    [createSpecimenAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.newSpecimen = action.payload;  
    },
    [createSpecimenAsync.rejected]: (state) => {
      state.loading = false;
    },
    [updateSpecimenAsync.pending]: (state) => {
      state.loading = true;
    },
    [updateSpecimenAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateSpecimenAsync.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { testReducer } = specimenSlice.actions;
export default specimenSlice.reducer;
