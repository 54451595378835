import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CardBody from "../../../components/common/Cards/CardBody/CardBody";
import CardContainer from "../../../components/common/Cards/CardContainer/CardContainer";
import Container from "../../../components/common/Container/Container";
import CustomTabs from "../../../components/common/CustomTabs/CustomTabs";
import PageHeader from "../../../components/common/PageHeader/PageHeader";
import SpaceDivider from "../../../components/common/SpaceDivider/SpaceDivider";
import PageContainer from "../../../components/Layout/PageContainer/PageContainer";
import {
  getSingleSpecimenDetailsAsync,
  updateSpecimenAsync,
} from "../store/specimenSlice";
import UpdateCatalogue from "./UpdateCatalogue/UpdateCatalogue";
import UpdateIdetails from "./UpdateIdetails/UpdateIdetails";
import UpdatePInfo from "./UpdatePInfo/UpdatePInfo";
import { MdArticle } from "react-icons/md";

const tabs = [
  {
    label: "IDetails",
  },
  {
    label: "PInfo",
  },
  {
    label: "Data Card",
  },
  {
    label: "Catalog",
  },
];
const UpdateSpecimen = () => {
  const { specimenId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const specimenDetails = useSelector(({ specimen }) => {
    return specimen?.specimenDetails;
  });
  const [selectedTab, setSelectedTab] = useState("IDetails");
  const [historyOfPreservations, setHistoryOfPreservations] = useState([{}]);
  const location = useLocation();

  useEffect(() => {
    const currentTab = new URLSearchParams(location.search).get("tab");
    setSelectedTab(currentTab);
  }, [location]);

  // const {
  //   handleSubmit,
  //   control,
  //   formState: { errors },
  //   setValue,
  // } = useForm({
  //   defaultValues: {},
  //   reValidateMode: "onChange",
  // });

  useEffect(() => {
    if (specimenId) {
      console.log("specimenId", specimenId);
      dispatch(getSingleSpecimenDetailsAsync({ specimenId }));
    }
  }, [specimenId]);
  useEffect(() => {
    console.log("specimenDetails", specimenDetails);
  }, [specimenDetails]);

  return (
    <PageContainer>
      <PageHeader
        title="Update Specimen"
        subTitle={`${specimenDetails?.iDetails?.englishName ? specimenDetails?.iDetails?.englishName : `Registration No: ${specimenDetails?.regNo}`}`}
        // subTitle={`Registration No: ${specimenDetails?.iDetails?.regNo}`}
        button={
          <Link
            to={`/specimen-details/${specimenDetails?._id}?tab=${selectedTab}`}
          >
            <Button
              startIcon={<MdArticle />}
              sx={{ borderRadius: 8 }}
              type="submit"
              variant="contained"
            >
              View Details
            </Button>
          </Link>
        }
      />

      <Container>
        <CustomTabs tabs={tabs} />
        {selectedTab === "IDetails" && specimenDetails ? (
          <UpdateIdetails specimenDetails={specimenDetails} />
        ) : null}
        {selectedTab === "PInfo" ? (
          <UpdatePInfo
            specimenDetails={specimenDetails}
            historyOfPreservations={historyOfPreservations}
            setHistoryOfPreservations={setHistoryOfPreservations}
          />
        ) : null}
        {/* {selectedTab === "Data Card" ? (
            <CardContainer width="w-full mt-5">
              <CardBody label="Data Card">
                <div className="p-6">
                  <div className="flex justify-center">
                    <p>Under Construction!</p>
                  </div>
                </div>
              </CardBody>
            </CardContainer>
          ) : null} */}
        {selectedTab === "Catalog" ? (
          <UpdateCatalogue
            specimenDetails={specimenDetails}
            historyOfPreservations={historyOfPreservations}
            setHistoryOfPreservations={setHistoryOfPreservations}
          />
        ) : null}
      </Container>
      {/* </form> */}
      <SpaceDivider />
    </PageContainer>
  );
};

export default UpdateSpecimen;
