import React from 'react'
import CardContainer from '../../../components/common/Cards/CardContainer/CardContainer'
import CardBody from '../../../components/common/Cards/CardBody/CardBody'

export default function DataCard() {
  return (
    <CardContainer width="w-full mt-5">
              <CardBody label="Data Card">
                <div className="p-6">
                  <div className="flex justify-center">
                    <p>Under Construction!</p>
                  </div>
                </div>
              </CardBody>
            </CardContainer>
  )
}
