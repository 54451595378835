import React, { useEffect, useState } from "react";
import CardContainer from "../../../../components/common/Cards/CardContainer/CardContainer";
import CardBody from "../../../../components/common/Cards/CardBody/CardBody";
import CustomTextField from "../../../../components/common/CustomTextField/CustomTextField";
import DisabledTextField from "../../../../components/common/DisabledTextField/DisabledTextField";
import FormFieldContainer from "../../../../components/common/FormFieldContainer/FormFieldContainer";
import { useForm } from "react-hook-form";
import FormContainer from "../../../../components/common/FormContainer/FormContainer";
import { Button } from "@mui/material";
import SubmitButton from "../../../../components/common/SubmitButton/SubmitButton";
import { useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { updateSpecimenAsync } from "../../store/specimenSlice";
import { convertDate, retrieveDate, showSuccessAlert } from "../../../../utils/utils";
import CustomDatePicker from "../../../../components/common/CustomDatePicker/CustomDatePicker";
import UncontrollrdCustomSelect from "../../../../components/common/CustomSelect/UncontrollrdCustomSelect";
import { groups } from "../../../../config/config";

export default function UpdateIdetails({  specimenDetails }) {
  const dispatch = useDispatch();
  const { specimenId } = useParams();
  const [collectionDate,setCollectionDate] = useState()
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      group:specimenDetails?.iDetails?.group
    },
    reValidateMode: "onChange",
  });
  const onSubmit = async (data) => {
    console.log("submitted Idetails", data);
    console.log("data: ", specimenId, data);
    const submittingData = {
      regNo: specimenDetails?.regNo,
      iDetails: {
        regNo: specimenDetails?.regNo,
        collectionDate: convertDate(collectionDate),
        group: data?.group,
        subGroup: data?.subGroup,
        groupSerial: data?.groupSerial,
        habitatType: data?.habitatType,
        category: data?.category,
        phylum: data?.phylum,
        class: data?.class,
        order: data?.order,
        family: data?.family,
        scientificName: data?.scientificName,
        englishName: data?.englishName,
        localName: data?.localName,
        referenceVP: data?.referenceVP,
        collectionPlace: data?.collectionPlace,
        district: data?.district,
        collector: data?.collector,
        weight: data?.weight,
        age: data?.age,
        sex: data?.sex,
        gpsNorth: data?.gpsNorth,
        gpsEast: data?.gpsEast,
        notes: data?.notes,
        reference: data?.reference,
        link: data?.link,
      },
      pInfo: {...specimenDetails?.pInfo},
      dataCard: {},
      catalogue: {...specimenDetails?.catalogue}
    };
    console.log("SubmittingData: ", submittingData);

    dispatch(updateSpecimenAsync({specimenId,submittingData})).unwrap().then((res) => {
      console.log(res);
      showSuccessAlert({title:'Success!',description:'Idetails Updated Successfully!'})
      Navigate(`/specimen-details/${specimenId}?tab=IDetails`)
    })
    .catch((err) => {
      console.log(err)
    });
  };
  useEffect(() => {
    console.log('specimenDetails',specimenDetails)
    setCollectionDate(retrieveDate(specimenDetails?.iDetails?.collectionDate));
    setValue("group", specimenDetails?.iDetails?.group);
    setValue("subGroup", specimenDetails?.iDetails?.subGroup);
    setValue("groupSerial", specimenDetails?.iDetails?.groupSerial);
    setValue("habitatType", specimenDetails?.iDetails?.habitatType);
    setValue("category", specimenDetails?.iDetails?.category);
    setValue("phylum", specimenDetails?.iDetails?.phylum);
    setValue("class", specimenDetails?.iDetails?.class);
    setValue("order", specimenDetails?.iDetails?.order);
    setValue("family", specimenDetails?.iDetails?.family);
    setValue("scientificName", specimenDetails?.iDetails?.scientificName);
    setValue("englishName", specimenDetails?.iDetails?.englishName);
    setValue("localName", specimenDetails?.iDetails?.localName);
    setValue("referenceVP", specimenDetails?.iDetails?.referenceVP);
    setValue("collectionPlace", specimenDetails?.iDetails?.collectionPlace);
    setValue("district", specimenDetails?.iDetails?.district);
    setValue("collector", specimenDetails?.iDetails?.collector);
    setValue("weight", specimenDetails?.iDetails?.weight);
    setValue("age", specimenDetails?.iDetails?.age);
    setValue("sex", specimenDetails?.iDetails?.sex);
    setValue("gpsNorth", specimenDetails?.iDetails?.gpsNorth);
    setValue("gpsEast", specimenDetails?.iDetails?.gpsEast);
    setValue("notes", specimenDetails?.iDetails?.notes);
    setValue("reference", specimenDetails?.iDetails?.reference);
    setValue("link", specimenDetails?.iDetails?.link);
  }, [specimenDetails]);
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <CardContainer width="w-full mt-5">
      {specimenDetails ? (
          <FormContainer label="Field Collection Datasheet" button={<SubmitButton />}>
          <div className="py-12 px-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
              <DisabledTextField
                label="Registration No"
                value={specimenDetails?.regNo}
              />
              <CustomDatePicker
                  label="Collection Date"
                  defaultValue={collectionDate}
                  setDate={setCollectionDate}
                />
              {/* <CustomTextField
                name="collectionDate"
                label="Collection Date"
                control={control}
              /> */}
              </div>
              <FormFieldContainer label="Basic Information">
                {/* <CustomTextField name="group" label="Group" control={control} /> */}
                <UncontrollrdCustomSelect
              control={control}
              name="group"
              label="Group"
              menus={groups}
            />
                <CustomTextField
                  name="subGroup"
                  label="Sub Group"
                  control={control}
                />

                <CustomTextField
                  name="groupSerial"
                  label="Group Serial"
                  control={control}
                />
                <CustomTextField
                name="habitatType"
                label="Habitat Type"
                control={control}
              />

              <CustomTextField
                name="category"
                label="Category"
                control={control}
              />
              </FormFieldContainer>
              <FormFieldContainer label="Classification">
              <CustomTextField name="phylum" label="Phylum" control={control} />

              <CustomTextField name="class" label="Class" control={control} />

              <CustomTextField name="order" label="Order" control={control} />

              <CustomTextField name="family" label="Family" control={control} />
              </FormFieldContainer>
              <FormFieldContainer label="Common Names">

                <CustomTextField
                  name="scientificName"
                  label="Scientific Name"
                  control={control}
                />
              <CustomTextField
                name="englishName"
                label="English Name"
                control={control}
              />

              <CustomTextField
                name="localName"
                label="Local name"
                control={control}
              />
              </FormFieldContainer>

              <FormFieldContainer label="Other information about specimen">
              <CustomTextField
                name="referenceVP"
                label="Reference (Vol/Page)"
                control={control}
              />

              <CustomTextField
                name="collectionPlace"
                label="Collection Place"
                control={control}
              />

              <CustomTextField
                name="district"
                label="District"
                control={control}
              />

              <CustomTextField
                name="collector"
                label="Collector"
                control={control}
              />

              <CustomTextField name="weight" label="Weight" control={control} />

              <CustomTextField name="age" label="Age" control={control} />

              <CustomTextField name="sex" label="Sex" control={control} />

              <CustomTextField
                name="gpsNorth"
                label="GPS North"
                control={control}
              />

              <CustomTextField
                name="gpsEast"
                label="GPS East"
                control={control}
              />
              </FormFieldContainer>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
              <CustomTextField name="notes" label="Notes" control={control} />

              <CustomTextField
                name="reference"
                label="Reference"
                control={control}
              />

              <CustomTextField name="link" label="Link" control={control} />
            </div>
          </div>
          </FormContainer>
      ) : null}
    </CardContainer></form>
  );
}
