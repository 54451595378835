export const groups = [
  {
    label: "Mammal",
    value: "Mammal",
    image:
      "https://padmamuseum.gov.bd/admin/photo_entry/img5/Large_Indian_Civet6.png",
  },
  {
    label: "Bird",
    value: "Bird",
    image:
      "https://padmamuseum.gov.bd/admin/photo_entry/img4/Common_Hoopoe55.png",
  },
  {
    label: "Reptile",
    value: "Reptile",
    image:
      "https://padmamuseum.gov.bd/admin/photo_entry/img1/Reptiles_collection62.jpg",
  },
  {
    label: "Amphibian",
    value: "Amphibian",
    image:
      "https://padmamuseum.gov.bd/admin/photo_entry/img4/Amphibian_collection_60.jpg",
  },
  {
    label: "Fish",
    value: "Fish",
    image:
      "https://www.padmamuseum.gov.bd/admin/photo_entry/img10/Knifefish_or_Chitol64.png",
  },
  {
    label: "Mollusc",
    value: "Mollusc",
    image: "https://padmamuseum.gov.bd/admin/photo_entry/img2/124.png",
  },
  {
    label: "Arthropod",
    value: "Arthropod",
    image:
      "https://www.padmamuseum.gov.bd/admin/photo_entry/img9/Butterfly_Moths_collection108.jpg",
  },
  {
    label: "Other Invertebrates",
    value: "Other Invertebrates",
    image:
      "https://www.padmamuseum.gov.bd/admin/photo_entry/img2/Butterfly_Moths_collection110.jpg",
  },
  {
    label: "Egg",
    value: "Egg",
    image: "https://padmamuseum.gov.bd/admin/gallery/img3/Egg20.jpg",
  },
  {
    label: "Nest",
    value: "Nest",
    image: "https://padmamuseum.gov.bd/admin/gallery/img11/Birds_Nests21.jpg",
  },
  {
    label: "Body Parts",
    value: "Body Parts",
    image: "https://padmamuseum.gov.bd/admin/photo_entry/img1/122.png",
  },
  {
    label: "Crafts",
    value: "Crafts",
    image:
      "https://www.padmamuseum.gov.bd/admin/photo_entry/img12/Goina_Nouka123.png",
  },
  {
    label: "Fishing Gear",
    value: "Fishing Gear",
    image: "https://padmamuseum.gov.bd/admin/gallery/img13/Fishing_Gear23.png",
  },
  { label: "Replica", value: "Replica", image: "/assets/reptile.jpeg" },
];
