import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../../components/common/CustomSelect/CustomSelect";
import Loader from "../../../components/common/Loader/Loader";
import { createUserAsync, getUsersAsync, updateUserAsync } from "../store/userSlice";

const EditUserForm = ({selectedData,setModalOpen}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(null);
  const [role, setRole] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
    reValidateMode: "onChange",
  });
  useEffect(() => {
    if (selectedData) {
      setValue("name", selectedData?.name);
      setValue("email", selectedData?.email);
      setValue("password", selectedData?.password);
      setRole(selectedData?.role);
    }},[selectedData])
  const onSubmit = async (data) => {
    console.log("data: ", data);
    const submittingData = {
      name: data.name,
      email: data.email,
      password: data.password,
      role
    };
    console.log("SubmittingData: ", submittingData);
    try {
      const res = await dispatch(updateUserAsync({userId:selectedData?._id,submittingData})).unwrap();
      dispatch(getUsersAsync())
      setModalOpen(false)
      navigate("/user");
      console.log("res", res);
    } catch (err) {
      console.log(err);
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
   <div className="mx-auto w-[300px]">
        {loading ? (
          <Loader height="" size={100} />
        ) : (
          <form className="py-6 " onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-6">
            <Controller
              control={control}
              name="name"
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <TextField
                  sx={{ borderRadius: "8px" }}
                  className="m-8 w-full "
                  variant="outlined"
                  {...field}
                  label="Name"
                  //   error={errors.name}
                  //   helperText={errors.name && errors.name?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <TextField
                  sx={{ borderRadius: "8px" }}
                  className="m-8 w-full "
                  variant="outlined"
                  {...field}
                  label="Email"
                  //   error={errors.name}
                  //   helperText={errors.name && errors.name?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <FormControl
                  {...field}
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    className="w-full"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              )}
            />
            <CustomSelect
                  label="Role"
                  defaultValue={role}
                  setSelected={setRole}
                  menus={[
                    { label: "Curator", value: "Curator" },
                    { label: "Staff", value: "Staff" },
                    { label: "Privileged Visitor", value: "Privileged Visitor" }
                  ]}
                />
            </div>
            
            <button class="bg-[#002D74] rounded-xl text-white py-2 mt-[36px] hover:scale-105 duration-300 w-full">
              Submit
            </button>
          </form>
        )}
      </div>
  );
};

export default EditUserForm;
