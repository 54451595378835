import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { IoAddCircleOutline } from "react-icons/io5";
import { VscPreview } from "react-icons/vsc";
// import data from '../../../fakeDB/pbm_aspecimen.json';
import { Link } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import CustomIconButton from "../CustomIconButton/CustomIconButton";

const columns = [
  { id: "regNo", label: "Regi. No", minWidth: 50, align: "center" },
  { id: "colNo", label: "Col. Date", minWidth: 120 },
  {
    id: "englishName",
    label: "English Name",
    minWidth: 150,
    align: "left",
  },
  {
    id: "group",
    label: "Group",
    minWidth: 140,
    // align: "left",
  },
  {
    id: "subGroup",
    label: "Sub Group",
    // label: "Size\u00a0(km\u00b2)",
    minWidth: 140,
    // align: "left",
  },
  {
    id: "action",
    label: "Action",
    // label: "Size\u00a0(km\u00b2)",
    minWidth: 170,
    align: "center",
  },
];
// const rows = [];

function createData(regNo, colNo, englishName, group, subGroup, action) {
  return { regNo, colNo, englishName, group, subGroup, action };
}

// onChange={(event) => {
//   console.log("data: ", data);
//   const matches = data.filter(
//     (country) =>
//       country.name
//         .toLowerCase()
//         .includes(event.target.value.toLowerCase()) ||
//       country.region
//         .toLowerCase()
//         .includes(event.target.value.toLowerCase())
//   );
//   console.log("matches: ", matches);
//   setCountries(matches);
// }}

export default function CustomTable({label, data,totalCount, setSkip,setLimit }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(()=>{
    setSkip(page * rowsPerPage);
    setLimit(rowsPerPage);
  },[page,rowsPerPage])
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    if (data) {
      console.log("data: ", data);
      const tempRows = [];
      for (let i = 0; i < data.length; i++) {
        const {regNo} = data[i]
        
          // console.log('data[i]',data[i])
          if(data[i].iDetails){
            const { collectionDate, englishName, group, subGroup } =
          data[i]?.iDetails;
            tempRows.push({
              id:data[i]?._id,
              regNo: regNo,
              colNo: collectionDate,
              englishName: englishName,
              group: group,
              subGroup: subGroup,
            });
          }
        
      }
      setAllRows(tempRows);
      setRows(tempRows);
    }
  }, [data]);

  useEffect(() => {
    console.log("rows", rows);
  }, [rows]);

  return (
    <Paper
      className="rounded-[15px] w-[270px] sm:w-[300px] md:w-4/5 border"
      sx={{ borderRadius: "15px" }}
    >
      <div className="flex justify-between items-center p-4 rounded-t-[15px] border-b border-slate-300">
        <h2 className="text-xl font-bold text-info-content">{label} </h2>
        <Link to="/add-specimen">
          {/* <Button variant="outlined" startIcon={<IoAddCircleOutline />}>
            Add Specimen
          </Button> */}
          <CustomButton label="Add Specimen" icon={<IoAddCircleOutline />} />
        </Link>
      </div>

      <TableContainer>
        {data?.length === 0 ? (
          <p className="text-center py-24">Sorry, No data available!</p>
        ) : (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      color: "#22a6b3",
                      fontSize: "16px",
                      minWidth: column.minWidth,
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        // console.log('value', value);
                        if (column.id === "action") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {/* <div  className="flex-center"> */}
                              <Link to={`/update-specimen/${row.id}?tab=IDetails`}>
                                <CustomIconButton icon={<FiEdit />} />
                              </Link>

                              {/* <CustomIconButton icon={<FiTrash2 />} /> */}
                              <Tooltip title="Click here to view the full details of this item.">
                                <Link to={`/specimen-details/${row.id}?tab=IDetails`}>
                                  <CustomIconButton icon={<VscPreview />} />
                                </Link>
                              </Tooltip>
                              {/* </div> */}
                            </TableCell>
                          );
                        } else
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                borderRight: "1px solid #E0E0E0",
                              }}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
}
