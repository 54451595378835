import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdAccountCircle } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../../components/Logo/Logo";
import Loader from "../../../components/common/Loader/Loader";
import { loginUserAsync } from "../store/userSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    reValidateMode: "onChange",
  });

  const onSubmit = async (data) => {
    console.log("data: ", data);
    const submittingData = {
      email: data.email,
      password: data.password,
    };
    console.log("SubmittingData: ", submittingData);
    try {
      const res = await dispatch(loginUserAsync(submittingData)).unwrap();
      navigate("/specimen");
      console.log("res", res);
      await localStorage.setItem("user", JSON.stringify(res));
      await localStorage.setItem("access_token", res?.token);
    } catch (err) {
      console.log(err);
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <section
      style={{ backgroundImage: `url(/assets/Padma-Bridge.webp)` }}
      className="bg-no-repeat bg-cover  min-h-screen flex items-center justify-center px-4"
    >
      <div className=" rounded-2xl md:w-[400px]  md:px-16 bg-white px-5 py-12 ">
        <Logo />
        {loading ? (
          <Loader height="" size={100} />
        ) : (
          <form className="py-6 space-y-4 " onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <TextField
                  sx={{ borderRadius: "8px" }}
                  className="m-8 w-full "
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdAccountCircle className="w-[25px] h-[25px]" />
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                  label="Email"
                  //   error={errors.name}
                  //   helperText={errors.name && errors.name?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => (
                <FormControl
                  {...field}
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    className="w-full"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              )}
            />
            <button class="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300 w-full">
              Login
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default Login;
