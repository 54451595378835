import Swal from "sweetalert2";
import DeleteDialog from "../../../components/common/AlertDialog/DeleteDialog";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const showSuccessAlert = ({
    title = "Congratulations!",
    subtitle = `Action successful!`,
  }) => {
    Swal.fire({
      icon: "success",
      iconColor: "#000",
      title: title,
      text: subtitle,
      timer: 2000,
      showConfirmButton: false,
    });
  };
  
  export function showDeleteWarning() {
    return MySwal.fire({
      title: <DeleteDialog />,
      text: "",
      icon: "",
      iconColor: "#012B3E",
      showCancelButton: true,
      confirmButtonColor: "#012B3E",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        actions: "button-position",
        cancelButton: "order-1",
        confirmButton: "order-2",
        title: "title-style",
      },
    });
  }