import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../../components/Layout/PageContainer/PageContainer";
import Container from "../../components/common/Container/Container";
import Loader from "../../components/common/Loader/Loader";
import SpaceDivider from "../../components/common/SpaceDivider/SpaceDivider";
import SpeciesProfileTable from "./SpeciesProfileTable/SpeciesProfileTable";
import { getSpeciesProfilesAsync } from "./store/speciesProfileSlice";
import CustomSelect from "../../components/common/CustomSelect/CustomSelect";
import { groups } from "../../config/config";
import SearchField from "../../components/common/SearchField/SearchField";

const Home = () => {
  const dispatch = useDispatch();
  const speciesProfiles = useSelector(({ speciesProfile }) => {
    return speciesProfile?.speciesProfiles;
  });
  const [searchText, setSearchText] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  
  useEffect(() => {
    dispatch(getSpeciesProfilesAsync({keyword:searchText,skip,limit,group:selectedGroup}));
  }, [searchText,skip,limit,selectedGroup]);

  useEffect(() => {
    console.log("speciesProfiles", speciesProfiles);
  }, [speciesProfiles]);
  useEffect(() => {
    console.log("selectedGroup", selectedGroup);
  }, [selectedGroup]);

  return (
    <PageContainer>
      {speciesProfiles ? <>
      <Container>
        <div className="bg-info-content flex items-center pl-5 h-36  rounded-br-2xl rounded-tl-2xl w-4/5 my-6">
          <h2 className="text-white text-[28px] xl:text-[36px] font-bold">
            Specimen Profiles
          </h2>
        </div>
      </Container>
      <Container>
        <div className="flex items-center gap-4 my-6">
          <SearchField sx='my-[16px]' searchText={searchText} setSearchText={setSearchText} />
          <CustomSelect className="w-[353px]" label='Group' menus={groups} setSelected={setSelectedGroup} />
        </div>
        
        <SpeciesProfileTable
          data={speciesProfiles.data}
          totalCount={speciesProfiles.totalCount}
          setSkip={setSkip}
          setLimit={setLimit}
        />
      </Container>
      </> : <Loader height='h-screen' size={100} />}
      
      <SpaceDivider />
    </PageContainer>
  );
};

export default Home;
