import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

const CustomTextField = ({ name, control, label, disabled }) => {
  return (
    <Controller
      control={control}
      name={name}
      // rules={{
      //   required: "This field is required.",
      // }}
      render={({ field }) => (
        <TextField
          sx={{ borderRadius: "8px" }}
          className="m-8 w-full !rounded-xl"
          variant="outlined"
          disabled={disabled}
          // focused
          // inputProps={{ readOnly: true }}
          {...field}
          label={label}
          InputLabelProps={{ shrink: field.value }}
          //   error={errors.name}
          //   helperText={errors.name && errors.name?.message}
        />
      )}
    />
  );
};

export default CustomTextField;
