const CardBody = ({ label, children }) => {
  return (
    <div>
      <div className="border-b-[1px] p-3 pl-5">
        <h3 className="font-bold text-info-content text-xl">{label}</h3>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default CardBody;
