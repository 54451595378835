/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import PageContainer from "../../components/Layout/PageContainer/PageContainer";
// import NotFoundGif from "/assets/pageNotFound.gif";

const PageNotFound = () => {
  return (
    <PageContainer>
      <div className="flex justify-center">
        <img src="/assets/pageNotFound.gif" alt="404 photo" />
      </div>
    </PageContainer>
  );
};

export default PageNotFound;
