import React, { useEffect } from "react";
import CardBody from "../../../components/common/Cards/CardBody/CardBody";
import CardRow from "../../../components/common/Cards/CardRow/CardRow";
import CardContainer from "../../../components/common/Cards/CardContainer/CardContainer";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

// const data = [
//   {
//     processingDate: "gsdf",
//     processedBy: "sdfg",
//     descriptionOfProcessing: "sdfg",
//   },
//   {
//     descriptionOfProcessing: "sfdg",
//     processedBy: "gjhgjf",
//     processingDate: "jgj",
//   },
//   {
//     processingDate: "dsafd",
//     processedBy: "fas",
//     descriptionOfProcessing: "fdasf",
//   },
// ];

function HistoryOfPreservationTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "#002B3D", fontWeight: "600" }}>
              History of preservation
            </TableCell>
            <TableCell sx={{ color: "#002B3D", fontWeight: "600" }}>
              Processing Date
            </TableCell>
            <TableCell sx={{ color: "#002B3D", fontWeight: "600" }}>
              Processed By
            </TableCell>
            <TableCell sx={{ color: "#002B3D", fontWeight: "600" }}>
              Description of Processing
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{`History of preservation - ${index + 1}`}</TableCell>
              <TableCell>{row.processingDate}</TableCell>
              <TableCell>{row.processedBy}</TableCell>
              <TableCell>{row.descriptionOfProcessing}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function PInfo({ specimenDetails, selectedTab }) {
  const historyOfPreservations = specimenDetails?.pInfo?.historyOfPreservations;
  useEffect(() => {
    console.log("specimenDetails", specimenDetails);
  }, [specimenDetails]);
  return (
    <CardContainer width="w-full mt-5">
      {specimenDetails ? (
        <CardBody label="Processing and Preservation Information Datasheet">
          <div className="py-12 px-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
              <CardRow label="Registration No" value={specimenDetails?.regNo} />

              <CardRow
                label="Scientific Name"
                value={specimenDetails?.iDetails?.scientificName}
              />

              <CardRow
                label="English Name"
                value={specimenDetails?.iDetails?.englishName}
              />
              <CardRow
                label="Field Collection No"
                value={specimenDetails?.pInfo?.fieldCollectionNo}
              />
              <CardRow
                label="Museum Registration No"
                value={specimenDetails?.pInfo?.museumRegNo}
              />
              <CardRow
                label="Catalog No"
                value={specimenDetails?.catalogue?.catalogNo}
              />
              <CardRow
                label="MIS Sheet No (if any)"
                value={specimenDetails?.pInfo?.misSheetNo}
              />

              <CardRow
                label="Name of Specimen"
                value={specimenDetails?.iDetails?.localName}
              />

              <CardRow
                label="Museum Entry Date"
                value={specimenDetails?.iDetails?.collectionDate}
              />
            </div>

            {historyOfPreservations ? (
              <div className="my-8">
                <h2 className="my-3 font-semibold">History of Preservation</h2>
                <HistoryOfPreservationTable data={historyOfPreservations} />
              </div>
            ) : null}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
              <CardRow
                label="Species Identified By"
                value={specimenDetails?.pInfo?.speciesIdentifiedBy}
              />

              <CardRow
                label="Species ID Confirmed By"
                value={specimenDetails?.pInfo?.speciesIdConfirmedBy}
              />

              <CardRow
                label="Date of Specimen Confirmation"
                value={specimenDetails?.pInfo?.speciesConfirmationDate}
              />
            </div>
          </div>
        </CardBody>
      ) : null}
    </CardContainer>
  );
}
