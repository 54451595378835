import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import PageContainer from "../../../components/Layout/PageContainer/PageContainer";
import Container from "../../../components/common/Container/Container";
import CustomTabs from "../../../components/common/CustomTabs/CustomTabs";
import PageHeader from "../../../components/common/PageHeader/PageHeader";
import SpaceDivider from "../../../components/common/SpaceDivider/SpaceDivider";
import {
  getSingleSpecimenDetailsAsync
} from "../store/specimenSlice";
import Catalog from "./Catalog";
import DataCard from "./DataCard";
import IDetails from "./IDetails";
import PInfo from "./PInfo";

const tabs = [
  {
    label: "IDetails",
  },
  {
    label: "PInfo",
  },
  {
    label: "Data Card",
  },
  {
    label: "Catalog",
  },
];
const SpecimenDetails = () => {
  const { specimenId } = useParams();
  const dispatch = useDispatch();
  const specimenDetails = useSelector(({ specimen }) => {
    return specimen?.specimenDetails;
  });
  const [selectedTab, setSelectedTab] = useState("IDetails");
  const location = useLocation();

  useEffect(() => {
    const currentTab = new URLSearchParams(location.search).get("tab");
    setSelectedTab(currentTab);
  }, [location]);

  useEffect(() => {
    if (specimenId) {
      console.log("specimenId", specimenId);
      dispatch(getSingleSpecimenDetailsAsync({ specimenId }));
    }
  }, [specimenId]);
  useEffect(() => {
    console.log("specimenDetails", specimenDetails);
  }, [specimenDetails]);

  return (
    <PageContainer>
      <PageHeader
        title="Specimen Details"
        subTitle={`${specimenDetails?.iDetails?.englishName ? specimenDetails?.iDetails?.englishName : `Registration No: ${specimenDetails?.regNo}`}`}
        // subTitle={`Registration No: ${specimenDetails?.iDetails?.regNo}`}
        button={
          <Link
            to={`/update-specimen/${specimenDetails?._id}?tab=${selectedTab}`}
          >
            <Button
              startIcon={<FiEdit />}
              sx={{ borderRadius: 8 }}
              type="submit"
              variant="contained"
            >
              Update Details
            </Button>
          </Link>
        }
      />
      <Container>
        <CustomTabs tabs={tabs} />
        {selectedTab === "IDetails" ? (
          <IDetails specimenDetails={specimenDetails} />
        ) : null}
        {selectedTab === "PInfo" ? (
          <PInfo specimenDetails={specimenDetails} selectedTab={selectedTab} />
        ) : null}
        {selectedTab === "Data Card" ? <DataCard /> : null}
        {selectedTab === "Catalog" ? (
          <Catalog specimenDetails={specimenDetails} />
        ) : null}
      </Container>
      <SpaceDivider />
    </PageContainer>
  );
};

export default SpecimenDetails;
