import React from "react";
import { NavLink, useLocation } from "react-router-dom";

export default function SidebarItem({ label, icon, open, link }) {
  const location = useLocation();
  const { pathname } = location;
  // console.log("pathname", pathname, link, pathname.includes(link));
  const style =
    pathname.includes(link) || (pathname === "/" && link === "/specimen")
      ? "text-white bg-info-content dark:bg-info-content hover:bg-[#142f3a2d] hover:text-info-content"
      : `text-info-content hover:bg-[#142f3a2d]`;
  return (
    <NavLink to={link}>
      <li
        className={`group relative flex items-center gap-2.5 rounded-sm py-[16px] px-4 font-bold  duration-300 ease-in-out  ${style} break-words`}
      >
        {icon}
        {open && label}
      </li>
    </NavLink>
  );
}
