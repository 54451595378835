import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../../components/Layout/PageContainer/PageContainer";
import Container from "../../components/common/Container/Container";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import Loader from "../../components/common/Loader/Loader";
import SearchField from "../../components/common/SearchField/SearchField";
import SpaceDivider from "../../components/common/SpaceDivider/SpaceDivider";
import { getUserAsync, getUsersAsync } from "./store/userSlice";
import UserTable from "./UserTable/UserTable";

const Home = () => {
  const dispatch = useDispatch();
  const users = useSelector(({ user }) => {
    return user?.users;
  });
  const [searchText, setSearchText] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  
  useEffect(() => {
    dispatch(getUsersAsync({skip,limit}));
  }, [searchText,skip,limit]);

  useEffect(() => {
    console.log("users", users);
  }, [users]);

  return (
    <PageContainer>
      {users ? <>
      <Container>
        <div className="bg-info-content flex items-center pl-5 h-36  rounded-br-2xl rounded-tl-2xl w-full my-6">
          <h2 className="text-white text-[28px] xl:text-[36px] font-bold">
            Users
          </h2>
        </div>
      </Container>
      <Container>
        {/* <SearchField sx='my-[16px]' searchText={searchText} setSearchText={setSearchText} /> */}
        <UserTable
          data={users.data}
          totalCount={users.totalCount}
          setSkip={setSkip}
          setLimit={setLimit}
        />
      </Container>
      </> : <Loader height='h-screen' size={100} />}
      
      <SpaceDivider />
    </PageContainer>
  );
};

export default Home;
