import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";

export default function CustomDatePicker({ label,defaultValue, setDate, minDate}) {
  const [value, setValue] = useState(new Date());
  useEffect(()=>{
    if(defaultValue){
      setValue(defaultValue)
    }
  },[defaultValue])
  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          color="primary"
          className="w-full bg-[#F9F9F9]"
          value={value}
          label={label}
          onChange={(newValue) => {   
            setValue(newValue);
            setDate(newValue.$d);
          }}
          minDate={minDate}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
  );
}
