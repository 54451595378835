import React, { useEffect, useState } from "react";
import Container from "../../../components/common/Container/Container";
import CardBody from "../../../components/common/Cards/CardBody/CardBody";
import PageHeader from "../../../components/common/PageHeader/PageHeader";
import SpaceDivider from "../../../components/common/SpaceDivider/SpaceDivider";
import PageContainer from "../../../components/Layout/PageContainer/PageContainer";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from 'react-redux'
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import CardContainer from "../../../components/common/Cards/CardContainer/CardContainer";
import { createSpecimenAsync } from "../store/specimenSlice";
import Swal from 'sweetalert2';

const AddSpecimen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  // const isCreated = useSelector(({specimen})=>{return specimen?.newSpecimen})
  const [registrationNo, setRegistrationNo] = useState(null);
  useEffect(()=>{
    console.log('registrationNo', registrationNo)
  },[registrationNo])
  // useEffect(()=>{
  //   console.log('isCreated', isCreated)
  //   if(isCreated){
  //     console.log('Um in')
  //     // navigate("/update-specimen");
  //   }
  // },[isCreated])
  const handleSubmit = ()=>{
    dispatch(createSpecimenAsync({regNo: registrationNo})).unwrap().then((res) => {
      console.log('handleSubmit',res);
      Swal.fire({
        icon: 'success',
        title: 'Congratulations!',
        text: 'You have added a new specimen!',
        // showCancelButton: true,
        // confirmButtonText: 'Go to Admin Dashboard',
      })
      // .then((result) => {
      //   if (result.isConfirmed) {
      //     console.log('Redirecting to Admin Dashboard')
      // }})
      navigate(`/update-specimen/${res._id}?tab=IDetails`);
    })
    .catch((err) => {
      console.log(err);
    });
  }
  return (
    <PageContainer>
      <PageHeader title="Add New Specimen" />
      <Container>
        <CardContainer width='w-[300px] md:w-[500px]'>
          {/* <CardBody label="Add New Specimen"> */}
            <div className="  p-6">
              <h2 className="text-center text-2xl text-accent-focus font-bold">
                Please provide a unique Registration Number
              </h2>
              <div className="flex flex-col space-y-3 mt-6">
                <TextField
                 className="mb-5"
                 type='number'
                  required
                  id="outlined-required"
                  label="Registration Number"
                  defaultValue=""
                  onChange={(event)=>{setRegistrationNo(event.target.value)}}
                />
                <Button onClick={handleSubmit} variant="contained">Continue</Button>
              </div>
            </div>
          {/* </CardBody> */}
        </CardContainer>
      </Container>
      <SpaceDivider />
    </PageContainer>
  );
};

export default AddSpecimen;
