import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import { FormHelperText } from "@mui/material";

export default function CustomSelect({
  label,
  defaultValue,
  setSelected,
  menus,
  showError,
  withoutLabel,className=''
}) {
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
    setSelected(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={showError}>
        {label && (
          <InputLabel className="text-[14px]" id="demo-simple-select-label">
            {label}
          </InputLabel>
        )}
        <Select
          sx={
            withoutLabel && {
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 },
            }
          }
          color="primary"
          className={`bg-[#F9F9F9] text-sm ${className}`}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {menus?.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {showError && <FormHelperText>This field is required</FormHelperText>}
      </FormControl>
    </Box>
  );
}
