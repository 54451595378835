import React from 'react';

const SpaceDivider = ({gap}) => {
    return (
        <div className={gap ? `py-[${gap}px]` : 'py-8'}>
            
        </div>
    );
};

export default SpaceDivider;