import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { MdSearch } from "react-icons/md";

const SearchField = ({ searchText, setSearchText, sx = "" }) => {
  // const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className={sx}>
      <TextField
      fullWidth
        placeholder="Search anything..."
        value={searchText}
        onChange={handleSearchChange}
        variant="outlined"
        InputProps={{
          startAdornment: <MdSearch className="text-[22px] mr-[5px]" />,
        }}
      />
    </div>
  );
};

export default SearchField;
