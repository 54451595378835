import React from "react";
import { MdOutlineCancel } from "react-icons/md";

const DeleteDialog = () => {
  return (
    <div className='flex items-center ml-10 gap-4 py-8 border-b'>
      <MdOutlineCancel className='text-red-400 sm:text-[64px] text-[48px]' />
      <div className='flex flex-col items-start'>
        <p className='sm:text-[28px] text-base sm:m-2'>Are you sure?</p>
        <p className='sm:text-[20px] text-sm font-normal sm:m-2'>
          You won't be able to revert this!
        </p>
      </div>
    </div>
  );
};

export default DeleteDialog;
