import axios from 'axios';

// const url = 'dev';
const url = 'prod';

// const BASE_URL =
//   url === "dev"
//     ? "http://localhost:8000"
//     : url === "prod"
//     ? "https://padma-museum-backend.onrender.com"
//     : "https://padma-museum-backend.onrender.com";

const BASE_URL = "https://api.padmamuseum.gov.bd";

export const instance = axios.create({
  baseURL: BASE_URL,
});

