import React from "react";
import Container from "../../../components/common/Container/Container";
import CardBody from "../../../components/common/Cards/CardBody/CardBody";
import CardRow from "../../../components/common/Cards/CardRow/CardRow";
import CardContainer from "../../../components/common/Cards/CardContainer/CardContainer";
import FormFieldContainer from "../../../components/common/FormFieldContainer/FormFieldContainer";

export default function IDetails({ specimenDetails }) {
  return (
    <CardContainer width="w-full mt-5">
      {specimenDetails ? (
        <CardBody label="Field Collection Datasheet">
          <div className="py-12 px-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
              <CardRow
                value={specimenDetails?.iDetails?.regNo}
                label="Registration Number"
              />

              <CardRow
                label="Collection Date"
                value={specimenDetails?.iDetails?.CollectionDate}
              />
            </div>
            <FormFieldContainer label="Basic Information">
              <CardRow
                value={specimenDetails?.iDetails?.group}
                name="group"
                label="Group"
              />
              <CardRow
                value={specimenDetails?.iDetails?.subGroup}
                name="subGroup"
                label="Sub Group"
              />
              <CardRow
                value={specimenDetails?.iDetails?.groupSerial}
                name="groupSerial"
                label="Group Serial"
              />
              <CardRow
                value={specimenDetails?.iDetails?.habitatType}
                name="habitatType"
                label="Habitat Type"
              />
              <CardRow
                value={specimenDetails?.iDetails?.category}
                name="category"
                label="Category"
              />
            </FormFieldContainer>
            <FormFieldContainer label="Classification">
              <CardRow
                value={specimenDetails?.iDetails?.phylum}
                name="phylum"
                label="Phylum"
              />
              <CardRow
                value={specimenDetails?.iDetails?.class}
                name="class"
                label="Class"
              />
              <CardRow
                value={specimenDetails?.iDetails?.order}
                name="order"
                label="Order"
              />
              <CardRow
                value={specimenDetails?.iDetails?.family}
                name="family"
                label="Family"
              />
            </FormFieldContainer>
            <FormFieldContainer label="Common Names">
              <CardRow
                value={specimenDetails?.iDetails?.scientificName}
                name="scientificName"
                label="Scientific Name"
              />
              <CardRow
                value={specimenDetails?.iDetails?.englishName}
                name="englishName"
                label="English Name"
              />
              <CardRow
                value={specimenDetails?.iDetails?.localName}
                name="localName"
                label="Local name"
              />
            </FormFieldContainer>

            <FormFieldContainer label="Other information about specimen">
              <CardRow
                value={specimenDetails?.iDetails?.referenceVP}
                name="referenceVP"
                label="Reference (Vol/Page)"
              />
              <CardRow
                value={specimenDetails?.iDetails?.collectionPlace}
                name="collectionPlace"
                label="Collection Place"
              />
              <CardRow
                value={specimenDetails?.iDetails?.district}
                name="district"
                label="District"
              />
              <CardRow
                value={specimenDetails?.iDetails?.collector}
                name="collector"
                label="Collector"
              />
              <CardRow
                value={specimenDetails?.iDetails?.weight}
                name="weight"
                label="Weight"
              />
              <CardRow value={specimenDetails?.iDetails?.age} label="Age" />
              <CardRow value={specimenDetails?.iDetails?.sex} label="Sex" />
              <CardRow
                value={specimenDetails?.iDetails?.gpsNorth}
                label="GPS North"
              />
              <CardRow
                value={specimenDetails?.iDetails?.gpsEast}
                label="GPS East"
              />
            </FormFieldContainer>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
              <CardRow value={specimenDetails?.iDetails?.notes} label="Notes" />
              <CardRow
                value={specimenDetails?.iDetails?.reference}
                label="Reference"
              />
              <CardRow value={specimenDetails?.iDetails?.link} label="Link" />
            </div>
          </div>
        </CardBody>
      ) : null}
    </CardContainer>
  );
}
