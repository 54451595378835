import { Paper } from "@mui/material";
import { getGroupImage } from "../../utils/utils";

const GroupCard = ({ data }) => {
  // Extracting relevant information from the group object
  const { group, totalCount } = data;
  const bgImg = getGroupImage(group)
  console.log('GroupCard',getGroupImage(group) , totalCount);

  // Creating a style object for the card background
  const cardStyle = {
    backgroundImage: `url(${bgImg ? bgImg : "/assets/fallback-bg.jpeg"})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <Paper 
    className="w-[250px] h-[250px] !rounded-2xl shadow-xl border relative overflow-hidden"
    style={cardStyle}
  >
    <div className="absolute inset-0 bg-black opacity-[.50]"></div> {/* Add the overlay div */}
    <div className="text-center py-12 relative z-10">
      <p className="text-[36px] text-white font-bold mb-2">{group ? group : 'Unknown'}</p>
      <p className={`text-[64px] font-semibold text-yellow-400`}>{totalCount}</p>
    </div>
  </Paper>
  );
};

export default GroupCard;
