import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupCard from "../../components/Dashboard/GroupCard";
import PageContainer from "../../components/Layout/PageContainer/PageContainer";
import Container from "../../components/common/Container/Container";
import SpaceDivider from "../../components/common/SpaceDivider/SpaceDivider";
import { getSpecimenGroupsAsync } from "../specimen/store/specimenSlice";

const Home = () => {
  const dispatch = useDispatch();
  const specimenGroups = useSelector(({ specimen }) => {
    return specimen?.specimenGroups;
  });

  useEffect(() => {
    dispatch(getSpecimenGroupsAsync());
  }, []);

  useEffect(() => {
    console.log("specimenGroups", specimenGroups);
  }, [specimenGroups]);

  return (
    <PageContainer>
      <Container>
        <div className="bg-info-content flex items-center pl-5 h-36  rounded-br-2xl rounded-tl-2xl w-full my-6">
          <h2 className="text-white text-[28px] xl:text-[36px] font-bold">
            Overview
          </h2>
        </div>
      </Container>
      <Container>
        <div className="flex flex-wrap gap-4">
          {specimenGroups && specimenGroups.map((item, index) => (
            <GroupCard key={index} data={item} />
          ))}
        </div>
      </Container>
      <SpaceDivider />
    </PageContainer>
  );
};

export default Home;
