import React from 'react';

const FormFieldContainer = ({label, children}) => {
    return (
        <div className="w-full p-6 border rounded-md my-[16px]">
              <h2 className="my-3 font-semibold uppercase">
              {label}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-5">
              {children}
              </div>
            </div>
    );
};

export default FormFieldContainer;