import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../../axios";

const moduleName = "speciesProfile";
const apiRoute = `/species-profiles`;

export const getSpeciesProfilesAsync = createAsyncThunk(
  `${moduleName}/getSpeciesProfilesAsync`,
  async (payload) => {
    const response = await instance.get(`${apiRoute}?keyword=${payload?.keyword || ''}&skip=${payload?.skip || 0}&limit=${payload?.limit || 10}&group=${payload?.group || null}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = response.data;
    return data;
  }
);


export const createSpeciesProfileAsync = createAsyncThunk(
  `${moduleName}/createSpeciesProfileAsync`,
  async (payload) => {
    console.log("createSpeciesProfileAsync", payload);

    const response = await instance.post(`${apiRoute}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    
    const data = response.data;
    console.log("data", data);
    return data;
  }
);
export const updateSpeciesProfileAsync = createAsyncThunk(
  `${moduleName}/updateSpeciesProfileAsync`,
  async ({speciesProfileId,submittingData}) => {
    console.log("updateSpeciesProfileAsync", {speciesProfileId,submittingData});

    const response = await instance.put(`${apiRoute}/${speciesProfileId}`, submittingData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    
    const data = response.data;
    console.log("data", data);
    return data;
  }
);

export const deleteSpeciesProfileAsync = createAsyncThunk(
  `${moduleName}/deleteSpeciesProfileAsync`,
  async (speciesProfileId) => {
    console.log("deleteSpeciesProfileAsync", {speciesProfileId});

    const response = await instance.delete(`${apiRoute}/${speciesProfileId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = response.data;
    console.log("data", data);
    return data;
  }
);


const speciesProfileSlice = createSlice({
  name: moduleName,
  initialState: {
    publicSpeciesProfile: null,
    data: null,
    speciesProfile: null,
    speciesProfileDetails: null,
    speciesProfiles: null,
    specimenDetails: null,
    newSpeciesProfile: null,
    isAuthenticated: false,
    loading: false,
  },
  reducers: {
    checkIfAuthenticated: (state) => {
      if(localStorage.getItem("speciesProfile") || state.speciesProfile) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    },
    logout: (state) => {
      // This action will reset the speciesProfile data and isAuthenticated status
      state.speciesProfile = null;
      state.isAuthenticated = false;
      localStorage.removeItem("speciesProfile");
    },
  },
  extraReducers: {
    
    [getSpeciesProfilesAsync.pending]: (state) => {
      state.loading = true;
    },
    [getSpeciesProfilesAsync.fulfilled]: (state, action) => {
      // console.log('action', action)
      state.loading = false;
      state.speciesProfiles = action.payload;
    },
    [getSpeciesProfilesAsync.rejected]: (state) => {
      state.loading = false;
    },
    [createSpeciesProfileAsync.pending]: (state) => {
      state.loading = true;
    },
    [createSpeciesProfileAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.newSpeciesProfile = action.payload;  
    },
    [createSpeciesProfileAsync.rejected]: (state) => {
      state.loading = false;
    },
    [updateSpeciesProfileAsync.pending]: (state) => {
      state.loading = true;
    },
    [updateSpeciesProfileAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateSpeciesProfileAsync.rejected]: (state) => {
      state.loading = false;
    },
    [deleteSpeciesProfileAsync.pending]: (state) => {
      state.loading = true;
    },
    [deleteSpeciesProfileAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteSpeciesProfileAsync.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { checkIfAuthenticated, logout } = speciesProfileSlice.actions;
export default speciesProfileSlice.reducer;
