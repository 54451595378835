import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "../../components/Layout/PageContainer/PageContainer";
import Container from "../../components/common/Container/Container";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import Loader from "../../components/common/Loader/Loader";
import SearchField from "../../components/common/SearchField/SearchField";
import SpaceDivider from "../../components/common/SpaceDivider/SpaceDivider";
import { getSpecimenAsync } from "./store/specimenSlice";
import CustomSelect from "../../components/common/CustomSelect/CustomSelect";
import { groups } from "../../config/config";
import { useParams } from "react-router-dom";

const Home = () => {
  const { group } = useParams();
  const dispatch = useDispatch();
  const specimens = useSelector(({ specimen }) => {
    return specimen?.specimens;
  });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getSpecimenAsync({
        keyword: searchText,
        skip,
        limit,
        group: selectedGroup,
      })
    );
  }, [searchText, skip, limit, selectedGroup]);

  useEffect(() => {
    console.log("specimens", specimens);
  }, [specimens]);
  useEffect(() => {
    console.log("group", group);
    if (group) setSelectedGroup(group);
  }, [group]);

  return (
    <PageContainer>
      {specimens ? (
        <>
          <Container>
            <div className="bg-info-content flex items-center pl-5 h-36  rounded-br-2xl rounded-tl-2xl w-full my-6">
              <h2 className="text-white text-[28px] xl:text-[36px] font-bold">
                {group ? group : `Animal Specimen`}
              </h2>
            </div>
          </Container>
          <Container>
            <div className="flex items-center gap-4">
              <SearchField
                sx="my-[16px] w-[400px]"
                searchText={searchText}
                setSearchText={setSearchText}
              />
              {group ? null : <CustomSelect
                className="w-[353px]"
                label="Group"
                menus={groups}
                // defaultValue={selectedGroup}
                setSelected={setSelectedGroup}
              />}
            </div>

            <CustomTable
            label={group ? group : `Animal Specimen`}
              data={specimens.data}
              totalCount={specimens.totalCount}
              setSkip={setSkip}
              setLimit={setLimit}
            />
          </Container>
        </>
      ) : (
        <Loader height="h-screen" size={100} />
      )}

      <SpaceDivider />
    </PageContainer>
  );
};

export default Home;
