import Modal from "@mui/material/Modal";
import React from "react";
import { MdClear } from "react-icons/md";

const CustomModal = ({
  title,width='',padding='p-6',height='',
  modalOpen,
  setModalOpen,
  children,
  showClose = true
}) => {
  return (
    <Modal
      className="overflow-scroll flex justify-center items-center"
      open={modalOpen}
      onClose={() => {setModalOpen(false)}}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={`bg-white border border-[#EBEBEB] rounded-[2px] mx-[8px] overflow-auto ${padding} ${height} ${width}`}>
        <div className="flex items-center justify-between">
          <h3 className="text-[#2C2C2C] text-[22px] font-semibold">{title}</h3>
          {showClose && <MdClear
            className="text-[#2C2C2C]  text-[22px]  cursor-pointer"
            onClick={() => {
              setModalOpen(false);
            }}
          />}
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
