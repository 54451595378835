import { Route, Routes } from "react-router-dom";
import PageNotFound from "./main/PageNotFound/PageNotFound";
import Protected from "./main/Protected/Protected";
import Login from "./main/User/Login/Login.js";
import MyProfile from "./main/User/MyProfile/MyProfile";
import User from "./main/User/user";
import SpeciesProfile from "./main/SpeciesProfile/SpeciesProfile";
import Dashboard from "./main/dashboard/Dashboard";
import AddSpecimen from "./main/specimen/AddSpecimen/AddSpecimen";
import Specimen from "./main/specimen/Specimen";
import UpdateSpecimen from "./main/specimen/UpdateSpecimen/UpdateSpecimen";
import SpecimenDetails from "./main/specimen/SpecimenDetails/SpecimenDetails";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkIfAuthenticated } from "./main/User/store/userSlice";

function App() {

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(checkIfAuthenticated())
  },[dispatch])

  const isAuthenticated = useSelector(({user})=>{
    return user.isAuthenticated
  })

  useEffect(()=>{
    console.log('isAuthenticated',isAuthenticated)
  },[isAuthenticated])
  return (
    <div className="bg-[#f9f9f963] h-[100%]">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <Protected isSignedIn={isAuthenticated}>
              <Specimen />
            </Protected>
          }
          isAuthenticated={isAuthenticated}
        />
        <Route
          path="/specimen"
          element={
            <Protected isSignedIn={isAuthenticated}>
              <Specimen />
            </Protected>
          }
          isAuthenticated={isAuthenticated}
        />
        <Route
          path="/specimen/:group"
          element={
            <Protected isSignedIn={isAuthenticated}>
              <Specimen />
            </Protected>
          }
          isAuthenticated={isAuthenticated}
        />
        <Route
          path="/my-profile"
          element={
            <Protected isSignedIn={isAuthenticated}>
              <MyProfile />
            </Protected>
          }
          isAuthenticated={isAuthenticated}
        />
        <Route path="/my-profile" element={<User />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="/add-specimen"
          element={
            <Protected isSignedIn={isAuthenticated}>
              <AddSpecimen />
            </Protected>
          }
          isAuthenticated={isAuthenticated}
        />
        <Route
          path="/update-specimen/:specimenId"
          element={
            <Protected isSignedIn={isAuthenticated}>
              <UpdateSpecimen />
            </Protected>
          }
          isAuthenticated={isAuthenticated}
        />
        <Route
          path="/specimen-details/:specimenId"
          element={
            <Protected isSignedIn={isAuthenticated}>
              <SpecimenDetails />
            </Protected>
          }
          isAuthenticated={isAuthenticated}
        />
        <Route path="/user" element={<User />} />
        <Route path="/species-profile" element={<SpeciesProfile />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
