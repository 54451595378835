import React from "react";
import Container from "../Container/Container";

const PageHeader = ({ title, subTitle, button }) => {
  return (
    <Container>
      <div className="bg-info-content flex justify-between items-center px-5 h-36  rounded-br-2xl rounded-tl-2xl w-full my-6">
        <div>
          <h2 className="text-white text-[28px] xl:text-[36px] font-bold">
            {title}
          </h2>
          <h2 className="text-white text-[16px] xl:text-[18px] font-semibold">
            {subTitle}
          </h2>
        </div>

        {button}
      </div>
    </Container>
  );
};

export default PageHeader;
