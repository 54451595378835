import { configureStore } from '@reduxjs/toolkit'
import userSlice from '../main/User/store/userSlice'
import specimenSlice from '../main/specimen/store/specimenSlice'
import speciesProfileSlice from '../main/SpeciesProfile/store/speciesProfileSlice'

const store= configureStore({
   reducer: {
      specimen: specimenSlice,
      user: userSlice,
      speciesProfile: speciesProfileSlice
   }
})


export default store
