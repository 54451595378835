import moment from 'moment';
import Swal from 'sweetalert2';
import { groups } from '../config/config';


export const getUniqueItems = (array) => {
    return [...new Set(array)];
}

export const convertDate = (date) => {
  return moment(date).format('YYYY-M-DD');
};
export const retrieveDate = (date) => {
  return moment(date).toDate();
};

export const convertTime = (time) => {
  return moment(time).format('HH:mm:ss');
};

export const toastify = ({ icon = "success", title = "Action Successful" }) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  Toast.fire({
    icon: icon,
    title: title,
  });
};


export const showErrorAlert = () => {
  Swal.fire({
    icon: "error",
    iconColor: '#000',
    title: "Oops...!",
    text: "Something went wrong!",
    timer: 2000,
    showConfirmButton: false,
  });
}

export const showSuccessAlert = ({title="Congratulations!",description='Action Successful!'}) => {
  Swal.fire({
    icon: "success",
    // iconColor: '#000',
    title: title,
    text: description,
    timer: 2000,
    showConfirmButton: false,
  });
}

export function generateDirectDownloadLink(shareUrl) {
  let fileId = shareUrl.match(/file\/d\/([a-zA-Z0-9-_]+)/);
  console.log('download',`https://drive.google.com/uc?id=${fileId[1]}&export=download`)
  // return `https://drive.google.com/uc?id=${fileId}&export=download`;
  return shareUrl
}

export function generatePreviewLink(shareUrl) {
  let fileId = shareUrl.match(/file\/d\/([a-zA-Z0-9-_]+)/);
  return `https://drive.google.com/file/d/${fileId[1]}/preview`;
  return shareUrl
}

export function getGroupImage(groupName) {
  const group = groups.find(item=> item.value === groupName)
  console.log('group', group)
  return group?.image
}