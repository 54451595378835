import React from 'react'

export default function SubmitButton() {
  return (
    <button
          className="rounded-full border bg-info-content text-white text-[24px] py-1 px-4 font-bold"
          type="submit"
          variant="contained"
        >
          Save
        </button>
  )
}
