import React from "react";
import SidebarItem from "./SidebarItem";
import {
  MdAddBox,
  MdLabelImportantOutline,
  MdOutlineDashboard,
} from "react-icons/md";
import { TbAlien } from "react-icons/tb";
import { groups } from "../../../config/config";
import { FaUsers } from "react-icons/fa";
import { AiOutlineBug } from "react-icons/ai";

export default function SideBarItems({ open }) {
  return (
    <ul className="pt-[24px] space-y-2 w-[300px]">
      <SidebarItem
        link="/dashboard"
        label="Overview"
        icon={<MdOutlineDashboard className="text-[22px]" />}
        open={open}
      />
      <SidebarItem
        link="/species-profile"
        label="Species Profile"
        icon={<TbAlien className="text-[22px]" />}
        open={open}
      />
      <SidebarItem
        link="/add-specimen"
        label="Add Specimen"
        icon={<MdAddBox className="text-[22px]" />}
        open={open}
      />
      <SidebarItem
        link="/specimen"
        label="Specimen"
        icon={<AiOutlineBug className="text-[22px]" />}
        open={open}
      />
      <div className="ml-4">
        {groups.map((item) => (
          <SidebarItem
            link={`/specimen/${item.value}`}
            label={item.label}
            icon={<MdLabelImportantOutline className="text-[22px]" />}
            open={open}
          />
        ))}
      </div>
      <SidebarItem
        link="/user"
        label="Users"
        icon={<FaUsers className="text-[22px]" />}
        open={open}
      />

      {/* <SidebarItem
            link="/species-profile"
            label="Species Profile"
            icon={<AiOutlineBug className="text-[22px]" />}
            open={open}
          />
          <SidebarItem
            link="/catalog"
            label="Catalog"
            icon={<AiOutlineBug className="text-[22px]" />}
            open={open}
          /> */}
    </ul>
  );
}
