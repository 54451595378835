import React from "react";
import PageContainer from "../../../components/Layout/PageContainer/PageContainer";

const MyProfile = () => {
  return (
    <PageContainer>
      {/* <section
        //   style="font-family: Montserrat"
        class="  flex  font-medium items-center justify-center h-screen"
      > */}
        <section class="w-full md:w-3/5  bg-[#20354b] rounded-2xl m-2 md:m-5 px-3 md:px-8 py-6 shadow-lg">
          {/* <div class="flex items-center justify-between">
            <span class="text-gray-400 text-sm">2d ago</span>
            <span class="text-emerald-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                />
              </svg>
            </span>
          </div> */}
          <div class="mt-6 w-fit mx-auto">
            <img
              src="/assets/avater.jpg"
              class="rounded-full w-28 "
              alt="profile picture"
              srcset=""
            />
          </div>
          <div class="h-1 w-full bg-black mt-8 rounded-full">
            <div class="h-1 rounded-full w-4/5 mx-auto  bg-yellow-500 "></div>
          </div>
          <div class="mt-8 ">
            <h2 class="text-white font-bold text-2xl tracking-wide">
              Jonathan <br /> Smith
            </h2>
          </div>
          <p class="text-emerald-400 font-semibold mt-2.5">Admin</p>
          

          
          <div class="mt-3 text-white text-sm">
            <span class="text-gray-400 font-semibold mr-2">Email:</span>
            <span>nurnaby@gmail.com</span>
          </div>
        </section>
      {/* </section> */}
    </PageContainer>
  );
};

export default MyProfile;
