import React from 'react';

const Container = ({children}) => {
    return (
        <div className='flex justify-center '>
            <div className='w-full px-6'>{children}</div>
            
        </div>
    );
};

export default Container;